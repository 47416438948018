import ClassNames from "classnames";
import { FunctionComponent, ReactNode } from "react";
import { match, matchPath } from "react-router";
import { Link, Route } from "react-router-dom";
import { IconName } from "../types/index.js";
import { getURL } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  to?: string;
  icon: IconName;
  header?: ReactNode;
  content?: ReactNode;
  isHighlighted?: boolean;
  title: string;
  exact?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

const isMenuItemActive = (match: match | null, to?: string) => {
  if (!to) return false;

  const matchesBarePagesRoute =
    match &&
    matchPath(match.url, {
      path: getURL(":siteId", "pages", ":pageId", ":languageId"),
      exact: true,
    });

  // Set the menu item as active if it matches its own route and all descendant routes.
  // The match for `/:siteId/pages/:pageId` route on the other hand must be exact
  return matchesBarePagesRoute ? match && match.isExact : !!match;
};

const AdminNavItem: FunctionComponent<Props> = ({
  to,
  title,
  icon,
  header,
  content,
  children,
  isHighlighted,
  onClick = () => {},
}) => (
  <Route
    path={to}
    children={({ match }) => {
      const isActive = isMenuItemActive(match, to);
      const classNames = ClassNames("AdminNav__Item", {
        "AdminNav__Item--active": isActive,
        "AdminNav__Item--highlighted": isHighlighted,
      });

      return (
        <li className={classNames}>
          <div className="AdminNav__Header">
            {to && (
              <Link className="AdminNav__Control" to={to} title={title}>
                <Icon glyph={icon} /> {children}
              </Link>
            )}
            {!to && (
              <button
                className="AdminNav__Control Btn"
                title={title}
                onClick={onClick}
              >
                <Icon glyph={icon} /> {children}
              </button>
            )}
            {header}
          </div>
          {isActive && content && (
            <div className="AdminNav__Content">{content}</div>
          )}
        </li>
      );
    }}
  />
);

export default AdminNavItem;
