import { FunctionComponent } from "react";
import PageTree from "./PageTree.js";
import Sidebar from "./Sidebar.js";

const PageNavigation: FunctionComponent = () => (
  <Sidebar className="PageNavigation" heading="Seiten">
    <PageTree showActionButtons={true} showPopUpIcon={true} />
  </Sidebar>
);

export default PageNavigation;
