import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { getLanguages } from "../actions/Languages.js";
import { patchSite } from "../actions/Sites.js";
import { getActiveSite } from "../selectors/sites.js";
import { Language, SiteForm, StoreState } from "../types/index.js";
import Sidebar from "./Sidebar.js";
import SiteSettingsForm from "./SiteSettingsForm.js";

interface StateProps {
  siteSettings: SiteForm;
  allLanguages: Language[];
  siteId: string;
  isAdminUser: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const SiteSettings: FunctionComponent<ReduxProps> = ({
  getLanguages,
  patchSite,
  siteSettings,
  allLanguages,
  siteId,
  isAdminUser,
}) => {
  useEffect(() => {
    getLanguages();
  }, []);

  return (
    <Sidebar
      className="SiteSettings Sidebar--full"
      heading="Webseiten-Einstellungen"
    >
      <SiteSettingsForm
        formValues={siteSettings}
        allLanguages={allLanguages}
        onSubmit={(form) => patchSite(siteId, form)}
        isAdminUser={isAdminUser}
      />
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = ({
  sites,
  languages,
  user,
}): StateProps => {
  const site = getActiveSite(sites);
  const {
    id: siteId,
    vatId,
    legalRepresentative,
    googleTagManagerId,
    googleMapsApiKey,
  } = site;

  return {
    siteSettings: {
      vatId,
      legalRepresentative,
      languages: site.languages,
      googleTagManagerId,
      googleMapsApiKey,
    },
    allLanguages: languages,
    siteId,
    isAdminUser: user.isAdmin ?? false,
  };
};

const mapDispatchToProps = {
  getLanguages,
  patchSite,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SiteSettings);
