import { FunctionComponent, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import {
  copyModule,
  deleteModuleTranslation,
  postModule,
  translateModule,
} from "../actions/Modules.js";
import { patchSite } from "../actions/Sites.js";
import { getActiveSite } from "../selectors/sites.js";
import { Language, StoreState } from "../types/index.js";
import { getShortId, getURL } from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import ModuleDeleteDialog from "./ModuleDeleteDialog.js";
import PopUpsList from "./PopUpsList.js";
import Sidebar from "./Sidebar.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
}>;

interface StateProps {
  globalPopUpModuleId: string | null;
}

type ReduxProps = ConnectedProps<typeof connector>;

const PopUpsSettings: FunctionComponent<Props & ReduxProps> = ({
  postModule,
  deleteModuleTranslation,
  translateModule,
  patchSite,
  copyModule,
  match: {
    params: { siteId, languageId, pageId },
  },
  globalPopUpModuleId,
}) => {
  const history = useHistory();
  const getEditLink = (moduleId: string) =>
    getURL(siteId, "pages", pageId, languageId, "modules", moduleId);
  const [moduleIdToDelete, setModuleIdToDelete] = useState<string>();

  const onAdd = () => {
    const moduleId = getShortId();

    postModule({
      moduleId,
      siteId,
      pageId: null,
      moduleType: "PopUpModule",
      parentId: null,
      languageIds: [languageId],
      next: undefined,
    });

    history.push(getEditLink(moduleId));
  };

  const onTranslate = (moduleId: string, sourceLanguageId: Language) =>
    translateModule({
      siteId,
      moduleId,
      pageId: null,
      languageId,
      sourceLanguageId,
    });

  return (
    <Sidebar heading="Pop-ups">
      <PopUpsList
        label="Pop-ups erstellen und bearbeiten"
        onAdd={onAdd}
        onRemove={setModuleIdToDelete}
        onTranslate={onTranslate}
        onEdit={(moduleId) => history.push(getEditLink(moduleId))}
        onDuplicate={(moduleId) =>
          copyModule({
            moduleId,
            siteId,
            newModuleShortId: getShortId(),
            targetModuleId: null,
            targetPageId: null,
          })
        }
        activeId={globalPopUpModuleId}
        languageId={languageId}
        onSetActive={(moduleId: string) =>
          patchSite(siteId, {
            popUpModuleId: moduleId === globalPopUpModuleId ? null : moduleId,
          })
        }
      />

      <FormInfo>
        Sie haben die Möglichkeit, maximal ein Pop-Up zu markieren, welches
        global ausgespielt wird. Angelegte Pop-Ups können unter den
        Seiteneinstellungen einer Seite/mehreren Seiten zugewiesen werden.
      </FormInfo>

      {moduleIdToDelete !== undefined && (
        <ModuleDeleteDialog
          moduleId={moduleIdToDelete}
          onAction={(toRemove) => {
            if (toRemove === "nothing" || moduleIdToDelete === undefined) {
              return setModuleIdToDelete(undefined);
            }

            deleteModuleTranslation({
              deleteAllTranslations: toRemove === "allTranslations",
              languageId,
              moduleId: moduleIdToDelete,
              pageId: null,
              siteId,
            });
            setModuleIdToDelete(undefined);
          }}
          showModal={moduleIdToDelete !== undefined}
        />
      )}
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  sites,
}): StateProps => {
  const site = getActiveSite(sites);
  return { globalPopUpModuleId: site.popUpModuleId };
};

const mapDispatchToProps = {
  postModule,
  deleteModuleTranslation,
  translateModule,
  patchSite,
  copyModule,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PopUpsSettings);
