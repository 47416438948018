import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { postPage } from "../actions/Pages.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  Language,
  PageSettingsOnSave,
  PageTranslationType,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import {
  getDefaultPageTranslationSettings,
  getPageLimits,
  getURL,
} from "../utils/utils.js";
import PageSettings from "./PageSettings.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
}>;

interface StateProps {
  pagesLimitReached: boolean;
  hasGlobalPopUpModule: boolean;
}

type DispatchProps = {
  onSave: PageSettingsOnSave;
};

type ReduxProps = ConnectedProps<typeof connector>;

const getTitle = (pageTranslationType: PageTranslationType) => {
  switch (pageTranslationType) {
    case "page":
      return "Neue Seite";
    case "redirect":
      return "Neuer Link";
    case "folder":
      return "Neuer Ordner";
  }
};

const PageNew: FunctionComponent<Props & ReduxProps> = ({
  match: {
    params: { siteId, pageId, languageId },
  },
  onSave,
  pagesLimitReached,
  hasGlobalPopUpModule,
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const pageTranslationType =
    (searchParams.get("type") as PageTranslationType | null) ?? "page";

  const title = getTitle(pageTranslationType);
  const settings = getDefaultPageTranslationSettings(
    languageId,
    pageTranslationType
  );
  const closeLink = getURL(siteId, "pages", pageId, languageId);

  return (
    <PageSettings
      title={title}
      settings={settings}
      languageId={languageId}
      onSave={onSave}
      closeLink={closeLink}
      pageTranslationType={pageTranslationType}
      pagesLimitReached={pagesLimitReached}
      popUpModuleId={null}
      hasGlobalPopUpModule={hasGlobalPopUpModule}
    />
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  pages,
  sites,
}): StateProps => {
  const { pagesLimit, popUpModuleId } = getActiveSite(sites);
  const pageLimits = getPageLimits(pages, pagesLimit);

  return {
    pagesLimitReached: pageLimits.enabled.limitReached,
    hasGlobalPopUpModule: popUpModuleId !== null,
  };
};

// There is a typings bug in bind action creator
// https://github.com/piotrwitek/react-redux-typescript-guide/issues/6
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    history,
    match: {
      params: { siteId, pageId, languageId },
    },
  }: Props
): DispatchProps => ({
  onSave: async ({ translation, isEnabled, popUpModuleId }) => {
    const page = await dispatch(
      postPage(siteId, {
        parentId: pageId,
        translation,
        isEnabled,
        popUpModuleId,
      })
    );

    history.push(getURL(siteId, "pages", page.id, languageId));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(PageNew));
