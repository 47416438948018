import {
  ChangeEventHandler,
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { debounce } from "throttle-debounce";
import { APISite, GetSitesQueryParams } from "../../server/types/index.js";
import { getSites } from "../actions/Sites.js";
import { getPrimaryDomain } from "../selectors/sites.js";
import { isUserAuthenticated } from "../selectors/user.js";
import { StoreState } from "../types/index.js";
import { formatNumericDate, getURL, isDefined } from "../utils/utils.js";
import Icon from "./Icon.js";
import Sidebar from "./Sidebar.js";

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  sites: APISite[];
  showMoreItems: boolean;
  isAdmin: boolean;
}

const fetchSites = ({
  keepExisting,
  getSites,
  searchText,
}: {
  keepExisting: boolean;
  getSites: (
    params: GetSitesQueryParams,
    keepExisting: boolean
  ) => Promise<void>;
  searchText: string;
}) => getSites({ q: searchText ? searchText : undefined }, keepExisting);

const debouncedFetchSites = debounce(300, fetchSites);

type Props = RouteComponentProps<{
  siteId: string;
}>;

const Sites: FunctionComponent<Props & ReduxProps> = ({
  getSites,
  sites,
  isAdmin,
  showMoreItems,
}) => {
  const [searchText, setSearchText] = useState("");
  const loadMore: MouseEventHandler<HTMLButtonElement> = () =>
    fetchSites({
      keepExisting: true,
      getSites,
      searchText,
    });

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchText = e.currentTarget.value.toLowerCase();
    setSearchText(searchText);
    if (searchText && searchText.length < 3) return;
    debouncedFetchSites({
      keepExisting: false,
      getSites,
      searchText,
    });
  };

  useEffect(() => {
    fetchSites({
      keepExisting: false,
      getSites,
      searchText,
    });
  }, []);

  const ths = [
    "ID",
    "Hauptdomain",
    "Betrieb",
    "Ort",
    "Erstellt am",
    "Seitenlimit",
    "Gesetzlicher Vertreter",
  ];

  return (
    <Sidebar className="Sites Sidebar--full" heading="Webseiten">
      {isAdmin && (
        <div className="Sites__Heading">
          <div className="Sites__Search Form">
            <Icon glyph="search" />
            <input
              type="search"
              value={searchText}
              placeholder="Suche für IDs und Betriebe"
              onChange={handleSearch}
            />
          </div>
        </div>
      )}
      <div className="Sites__List TableWrapper">
        <table className="Table Table--compact">
          <thead>
            <tr>
              {ths.map((title) => (
                <th className="Sites__ListCaption" key={title}>
                  {title}
                </th>
              ))}
              <th />
            </tr>
          </thead>
          <tbody>
            {sites.map((site) => {
              return (
                <tr className="Site" key={site.id}>
                  <td>{site.id}</td>
                  <td>
                    <a
                      href={"https://" + getPrimaryDomain(site)}
                      target="_blank"
                    >
                      {getPrimaryDomain(site)}
                    </a>
                  </td>
                  <td>{site.accommodation.name}</td>
                  <td>{site.accommodation.place}</td>
                  <td>{formatNumericDate(site.createdAt)}</td>
                  <td>{site.pagesLimit}</td>
                  <td>{site.legalRepresentative || "-"}</td>
                  <td className="Table__Cell--text-right">
                    <a
                      href={getURL(site.id, "pages")}
                      target="_blank"
                      className="Btn Btn--action"
                    >
                      Öffnen <Icon glyph="launch" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showMoreItems && (
        <button onClick={loadMore} className="Sites__LoadMore Btn">
          <Icon glyph="arrow-down" /> Zeige weitere Seiten
        </button>
      )}
    </Sidebar>
  );
};

const mapDispatchToProps = {
  getSites,
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = ({
  sites: { byId, allItems, noMoreItems },
  user,
}): StateProps => ({
  sites: allItems.map((id) => byId[id]).filter(isDefined),
  showMoreItems: !noMoreItems,
  isAdmin: isUserAuthenticated(user) ? user.isAdmin : false,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Sites);
