import { useDroppable } from "@dnd-kit/core";
import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { DragDropType, DragDropTypes } from "../types/index.js";

interface Props {
  index: number;
}

const ModuleDropSpace: FunctionComponent<Props> = ({ index }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: `module-drop-space-${index}`,
    data: {
      type: DragDropType.ModuleDropSpace,
      index,
    } satisfies DragDropTypes[DragDropType.ModuleDropSpace],
  });

  return (
    <div
      ref={setNodeRef}
      className={ClassNames("ModuleDropSpace", {
        "ModuleDropSpace--is-over": isOver,
      })}
    >
      <div className="ModuleDropSpace__Inner">Modul hier einfügen</div>
    </div>
  );
};

export default ModuleDropSpace;
