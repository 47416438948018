import { useDndContext } from "@dnd-kit/core";
import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { DragDropType, IconName } from "../types/index.js";
import { checkIsDragDropData } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  icon: IconName;
}

const MockModuleOverlay: FunctionComponent<Props> = ({ icon }) => {
  const { over } = useDndContext();
  const dropIsAllowed = over
    ? checkIsDragDropData(over.data, DragDropType.ModuleDropSpace) ||
      checkIsDragDropData(over.data, DragDropType.EmptyModules)
    : false;

  return (
    <Icon
      className={ClassNames("MockModuleOverlay", {
        "MockModuleOverlay--not-allowed": !dropIsAllowed,
      })}
      glyph={icon}
    />
  );
};

export default MockModuleOverlay;
