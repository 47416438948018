import classNames from "classnames";
import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconName } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  to: string;
  icon?: IconName;
  isActive: boolean;
  children: ReactNode;
}

const Tab: FunctionComponent<Props> = ({ children, icon, isActive, to }) => {
  const className = classNames("Tabs__Item", {
    "Tabs__Item--active": isActive,
  });

  return (
    <li className={className}>
      <Link to={to}>
        {icon && <Icon glyph={icon} />} {children}
      </Link>
    </li>
  );
};

export default Tab;
