import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { ModuleType, ModuleWhatToRemove, StoreState } from "../types/index.js";
import { keys } from "../utils/utils.js";
import ModalDialog from "./ModalDialog.js";

interface Props {
  showModal: boolean;
  moduleId: string;
  onAction: (toRemove: ModuleWhatToRemove) => void;
}

interface StateProps {
  moduleType: ModuleType;
  isUntranslated: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const ModuleDeleteDialog: FunctionComponent<Props & ReduxProps> = ({
  showModal,
  moduleType,
  isUntranslated,
  onAction,
}) => {
  const entityTerm = moduleType === "PopUpModule" ? "Pop-up" : "Modul";

  return (
    <>
      {!isUntranslated && (
        <ModalDialog
          isOpen={showModal}
          title="Modul löschen"
          onClose={() => onAction("nothing")}
          actions={[
            {
              title: "Ja",
              onClick: () => onAction("allTranslations"),
            },
            {
              title: "Nein, nur in dieser Sprache",
              onClick: () => onAction("translation"),
            },
          ]}
        >
          Soll das {entityTerm} in allen Sprachen gelöscht werden?
        </ModalDialog>
      )}
      {isUntranslated && (
        <ModalDialog
          isOpen={showModal}
          title="Modul löschen"
          onClose={(status) =>
            status === "refuted"
              ? onAction("nothing")
              : onAction("allTranslations")
          }
        >
          Soll das {entityTerm} wirklich gelöscht werden?
        </ModalDialog>
      )}
    </>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules },
  { moduleId }
): StateProps => {
  const { type, translations } = modules.byId[moduleId];

  return {
    moduleType: type,
    isUntranslated: keys(translations).length === 1,
  };
};

const connector = connect(mapStateToProps);

export default connector(ModuleDeleteDialog);
