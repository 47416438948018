import { FunctionComponent } from "react";

interface Props {
  error: string | undefined;
}

const FormError: FunctionComponent<Props> = ({ error }) =>
  error ? <div className="Form__Error">{error}</div> : null;

export default FormError;
