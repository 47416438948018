import { FunctionComponent } from "react";
import { Provider } from "react-redux";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { Store } from "redux";
import { StoreState } from "../types/index.js";
import Admin from "./Admin.js";
import ErrorBoundary from "./ErrorBoundary.js";
import Login from "./Login.js";
import LoginImprint from "./LoginImprint.js";

interface Props {
  store: Store<StoreState>;
}

const App: FunctionComponent<Props> = ({ store }) => (
  <ErrorBoundary>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/impressum" component={LoginImprint} />
          <Route path="/:siteId/pages/:pageId/:languageId" component={Admin} />
          <Route path="/:siteId" component={Admin} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </Provider>
  </ErrorBoundary>
);

export default App;
