import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { APISite } from "../../../server/types/index.js";
import { getActiveSite, getPrimaryDomain } from "../../selectors/sites.js";
import {
  Accommodation,
  BaseModuleProps,
  ColorScheme,
  PrivacyModuleSettings,
  StoreState,
  WindowState,
} from "../../types/index.js";
import { getActiveColorScheme, getTranslations } from "../../utils/utils.js";
import ModuleHeadings from "../ModuleHeadings.js";
import ModuleWithHeadings from "../ModuleWithHeadings.js";
import RichEditorWrapper from "../RichEditorWrapper.js";

interface Props extends BaseModuleProps<PrivacyModuleSettings> {}

interface StateProps {
  site: APISite;
  accommodation: Partial<Accommodation>;
  scheme: ColorScheme;
  i18n: typeof translations.en;
}

const email = (email?: string) =>
  email ? <a href={`mailto:${email}`}>{email}</a> : null;

const translations = {
  de: {
    title: "Datenschutz",
    content: (site: APISite, accommodation: Partial<Accommodation>) => (
      <>
        <h2>
          Informationen bei der Verarbeitung von personenbezogenen Daten für den
          Gast
        </h2>
        <p>
          Datenschutzerklärung im Sinne des Art. 13 der Verordnung (EU) 2016/679
          des Europäischen Parlaments und des Rates vom 27. April 2016 zum
          Schutz natürlicher Personen bei der Verarbeitung personenbezogener
          Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie
          95/46/EG (Datenschutz-Grundverordnung)
        </p>
        <p>
          Gemäß EU-Datenschutzgrundverordnung (Verordnung 2016/679 des
          Europäischen Parlaments und des Rates) informieren wir Sie über das
          Folgende.
        </p>
        <h3>NAME UND KONTAKTDATEN DES VERANTWORTLICHEN</h3>
        Verantwortlicher für die Verarbeitung der Daten ist{" "}
        {site.legalRepresentative}, {accommodation.name}, {accommodation.street}
        , {accommodation.zip} {accommodation.city} – Italien, Tel.
        {accommodation.phone}, E-Mail: {email(accommodation.email)}, Webseite:{" "}
        {getPrimaryDomain(site)}
        <h3>PERSONENBEZOGENE DATEN UND BESONDERE KATEGORIEN VON DATEN</h3>
        <p>Ihre personenbezogenen Daten sind insbesondere</p>
        <ul>
          <li>
            Ihre Stammdaten (Name, Vorname, Adresse, E-Mailadresse,
            Telefonnummer, Faxnummer, Geburtsdatum) sowie Sprache und
            KFZ-Kennzeichen
          </li>
          <li>die Daten in Reisedokumenten und Ausweisen</li>
          <li>
            die Daten zur Zahlungsart und in Zusammenhang mit Zahlungen (z.B.
            Bankkoordinaten, Kreditkartendaten)
          </li>
          <li>das Aufenthaltsdatum und die Aufenthaltsdauer</li>
          <li>Zugriffs,- Nutzungs- und Kommunikationsdaten.</li>
        </ul>
        <p>
          Besondere Kategorien von Daten sind Gesundheitsdaten, sowie Daten über
          besondere Bedürfnisse.
        </p>
        <h3>
          ZWECKE FÜR DIE VERARBEITUNG DER PERSONENBEZOGENEN DATEN UND
          RECHTSGRUNDLAGE FÜR DIE VERARBEITUNG
        </h3>
        <p>Die angegebenen Daten werden für folgende Zwecke verarbeitet:</p>
        <ul>
          <li>Erfüllung gesetzlicher Pflichten</li>
          <li>
            Abwicklung vorvertraglicher und vertraglicher Maßnahmen bezüglich
            des Aufenthalts (Buchung, Anfrage, Aufenthalt, Abreise, usw.)
          </li>
          <li>
            Weiterleitung von Mitteilungen und Telefonanrufen, welche an den
            Gast gerichtet sind
          </li>
          <li>
            Speicherung der Daten für die Erleichterung zukünftiger Buchungen
            und Anfragen
          </li>
          <li>
            Zusendung von Informationen, Angeboten und Neuigkeiten des Hotels
            und der Geschäftspartner mittels E-Mail, Post und anderen
            Telekommunikationsmitteln über das Hotel, die Umgebung, lokale
            Veranstaltungen, usw.
          </li>
          <li>
            Zugriffs,- Nutzungs- und Kommunikationsdaten werden aus
            Sicherheitsgründen verarbeitet und um die Funktion der Internetseite
            zu gewähren.
          </li>
        </ul>
        <p>Die Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind:</p>
        <ul>
          <li>
            gesetzliche oder rechtliche Verpflichtungen (Art. 6, Abs. 1, lit. c
            DSGVO);
          </li>
          <li>
            die Erfüllung der vorvertraglichen und vertraglichen Maßnahmen und
            Verpflichtungen (Art. 6, Abs. 1, lit. b DSGVO);
          </li>
          <li>
            die von Ihnen eingeholte Einwilligung (Art. 6, Abs. 1, lit. a
            DSGVO);
          </li>
          <li>
            die Wahrung berechtigter Interessen (Art. 6, Abs. 1, lit. f DSGVO).
          </li>
        </ul>
        <p>
          Über unsere Internetseite werden keine besonderen Kategorien von
          personenbezogenen Daten verarbeitet.
        </p>
        <p>
          Falls besondere Kategorien von Daten bei Ihrem Aufenthalt in{" "}
          {accommodation.name} verarbeitet werden müssen, ist dafür Ihre
          Einwilligung notwendig.
        </p>
        <h3>Buchen</h3>
        <p>
          Die Angabe Ihrer personenbezogenen Daten im entsprechenden
          Buchungsformular unserer Internetseite ist erforderlich, um eine
          Buchung vorzunehmen und die vertraglichen Leistungen in Anspruch zu
          nehmen. Die mit einem Sternchen gekennzeichneten Reisedaten müssen
          angegeben werden, damit eine Buchung gemacht werden kann.
        </p>
        <p>
          Wir setzen die Bezahlplattform eines Zahlungsproviders ein, um eine
          sichere Bezahlung mit Ihrer Kreditkarte zu gewährleisten.
        </p>
        <p>
          Bei einer Banküberweisung werden zusätzliche personenbezogene Daten,
          wie Kontoinhaber und IBAN verarbeitet.
        </p>
        <p>
          Die Angabe der Rechnungsdaten erfolgt freiwillig, ist aber
          erforderlich, falls Sie eine Rechnung benötigen.
        </p>
        <p>
          Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen
          Daten ist die Erfüllung von vorvertraglichen und vertraglichen
          Maßnahmen.
        </p>
        <p>
          Die Dauer der Speicherung bemisst sich nach den geltenden gesetzlichen
          Bestimmungen und Aufbewahrungspflichten und Ihre personenbezogenen
          Daten für die Buchung werden für jenen Zeitraum aufbewahrt, der für
          die Verarbeitung unbedingt notwendig ist.
        </p>
        <p>
          Jene Daten, welche aufgrund Ihrer Einwilligung verarbeitet werden,
          werden bis auf Widerruf gespeichert.
        </p>
        <h3>Anfragen</h3>
        <p>
          Die Angabe Ihrer personenbezogenen Daten im entsprechenden
          Anfrageformular unserer Internetseite ist erforderlich, um eine
          Anfrage zu machen und die vorvertraglichen und vertraglichen
          Leistungen in Anspruch zu nehmen. Die mit einem Sternchen
          gekennzeichneten Reisedaten müssen angegeben werden, damit eine
          Anfrage gemacht werden kann.
        </p>
        <p>
          Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen
          Daten ist die Erfüllung von vorvertraglichen und vertraglichen
          Maßnahmen.
        </p>
        <p>
          Die Dauer der Speicherung bemisst sich nach den geltenden gesetzlichen
          Bestimmungen und Aufbewahrungsplichten und Ihre personenbezogenen
          Daten für die Anfrage werden für jenen Zeitraum aufbewahrt, der für
          die Verarbeitung unbedingt notwendig ist.
        </p>
        <h3>Newsletter- und Werbeversand</h3>
        <p>
          Die Angabe Ihrer personenbezogenen Daten für die Zusendung von
          Informationen, Angeboten und Neuigkeiten des Betriebes erfolgt
          freiwillig. Durch Nichtbereitstellung der personenbezogenen Daten
          werden keine Angebote und Neuigkeiten übermittelt.
        </p>
        <p>
          Die Daten, welche Sie angeben, um Informationen, Angeboten und
          Neuigkeiten mittels Newsletter oder Post zu erhalten und welche
          aufgrund Ihrer Einwilligung verarbeitet werden, werden bis auf
          Widerruf gespeichert.
        </p>
        <h3>Zugriffs-, Nutzungs- und Kommunikationsdaten</h3>
        <h4>Cookies</h4>
        <h5>Was sind Cookies?</h5>
        <p>
          Cookies sind kleine Textdateien, die beim Besuch einer Website von
          Ihrem Internetbrowser heruntergeladen werden. Sobald Sie mit demselben
          Endgerät die Seite erneut aufrufen, wird das Cookie an die Website
          zurückgeschickt oder an eine andere Website, zu welches es gehört,
          weitergeleitet. Somit erkennt die Website, dass Sie mit diesem Browser
          bereits aufgerufen wurde und kann beim erneuten Aufruf der Seite die
          Benutzererfahrung verbessern. Cookies merken sich z.B. Ihre Vorlieben
          und passen die angezeigten Angebote an Ihre persönlichen Bedürfnisse
          und Interessen an. Cookies können, abhängig von ihrer Funktion und
          ihres Verwendungszweckes, in mehrere Kategorien unterteilt werden:
        </p>
        <ul>
          <li>Technische Cookies</li>
          <li>Profilierungscookies</li>
          <li>Cookies von Drittanbietern</li>
        </ul>
        <h5>Technische Cookies</h5>
        <p>
          Diese Cookies werden lediglich während der aktuellen Session auf Ihrem
          Rechner gespeichert und dienen dazu, dass die Website korrekt genutzt
          werden kann. Analytische Cookies, die für Zugriffsstatistiken
          verwendet werden, zählen zu den technischen Cookies. Sie sammeln
          allgemeine Informationen und ermöglichen keine Identifizierung des
          Besuchers.
        </p>
        <h5>Profilierungscookies</h5>
        <p>
          Diese Cookies werden benutzt, um das Surfverhalten der Benutzer samt
          Vorlieben, Gewohnheiten usw. zu speichern. Dadurch können dem Besucher
          Werbebotschaften zugesendet werden, welche im Zusammenhang mit den
          zuvor betrachteten Produkten stehen oder jenen Produkten ähneln.
        </p>
        <h5>Cookies von Drittanbietern</h5>
        <p>
          Durch Schließen des Info-Banners oder weiteres Navigieren akzeptieren
          Sie die Verwendung von Cookies.
        </p>
        <h5>Welche Cookies werden auf dieser Seite verwendet?</h5>
        <p>
          Diese Seite verwendet technische Cookies und Cookies von
          Drittanbietern.
        </p>
        <h5>Cookies deaktivieren</h5>
        <p>
          Gemäß dem Datenschutzgesetz können Sie zuvor akzeptierte und
          installierte Cookies jederzeit deaktivieren. Der Vorgang unterscheidet
          sich je nach verwendetem Browser. Eine detaillierte Erläuterung zur
          Deaktivierung in den verschiedenen Browsern finden Sie in den unten
          angeführten Links.
        </p>
        <ul>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?hl=de">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
              Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/de-de/HT201265">Safari</a>
          </li>
          <li>
            <a href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-löschen-63947406-40ac-c3b8-57b9-2a946a29ae09">
              Microsoft Edge
            </a>
          </li>
        </ul>
        <p>
          Sollte Sie keinen der oben genannten Browser verwenden, wählen Sie
          bitte die Option „Cookies“ in der „Hilfe“-Funktion, um zu erfahren, wo
          Ihre Cookies-Einstellungen zu finden sind. Einzelne nicht technische
          Cookies von Dritten können Sie deaktivieren und/oder löschen. Näheres
          hierzu finden Sie auf der Website der European Interactive Digital
          Advertising Alliance:{" "}
          <a href="https://www.youronlinechoices.eu">
            www.youronlinechoices.eu
          </a>
        </p>
        <p>
          <a href="#uc-corner-modal-show">Hier</a> können Sie die
          Cookie-Einstellungen vornehmen.
        </p>
        <h4>Server-Log-Dateien</h4>
        <p>
          Bei der Nutzung der Internetseite werden Daten automatisch erhoben und
          in Server-Log-Dateien gespeichert. Es handelt sich dabei um folgende
          Daten: IP-Adresse, Browser und Browserversion, Betriebssystem,
          Zugriffszeit und die übertragene Datenmenge.
        </p>
        <h4>Google Analytics</h4>
        <p>
          Unsere Internetseite verwendet Funktionen des Webanalysedienstes
          Google Analytics der Google LLC, 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, USA, um unser Onlineangebot zu optimieren. In diesem
          Zusammenhang können Daten in Drittländer übertragen werden. Durch das
          Privacy-Shield-Abkommen, unter welchem Google zertifiziert ist, wird
          die Einhaltung des europäischen Datenschutzrechtes gewährt.
        </p>
        <h4>Google Maps</h4>
        <p>
          Wir verwenden die Landkarten des Dienstes „Google Maps“ des Anbieters
          Google. Zu den verarbeiteten Daten können IP-Adressen und
          Standortdaten gehören, diese werden jedoch nicht ohne Ihre
          Einwilligung erhoben (beachten Sie dafür die Einstellungen Ihres
          Mobilgerätes).
        </p>
        <h4>SSL-Verschlüsselung und rechtliche Grundlage</h4>
        <p>
          SSL-Verschlüsselung: Um die Sicherheit Ihrer Daten bei der Übertragung
          zu gewähren, verwenden wir dem aktuellen Stand der Technik
          entsprechende Verschlüsselungsverfahren über https.
        </p>
        <p>
          Die Speicherung und Verarbeitung der Zugriffs-, Nutzungs- und
          Kommunikationsdaten erfolgt aus Sicherheitsgründen und um die Funktion
          der Internetseite zu gewährleisten. Die rechtliche Grundlage dafür ist
          das berechtige Interesse im Sinne des Art. 6, Abs. 1, lit. f DSGVO.
        </p>
        <h3>Social Media</h3>
        <p>
          Wir unterhalten Onlinepräsenzen in sozialen Netzwerken, um mit Kunden
          und Interessenten zu kommunizieren und zu informieren. Diesbezüglich
          können Daten der Nutzer auch außerhalb der Europäischen Union
          verarbeitet werden. Allerdings liegt für die Übertragung der Daten ein
          Angemessenheitsbeschluss der Europäischen Kommission gemäß Art. 45
          DSGVO bzw. geeignete Garantien gemäß Art. 46 DSGVO vor.
        </p>
        <p>
          Die Datenverarbeitung im Zusammenhang mit dem Besuch unserer Auftritte
          in den sozialen Netzwerken erfolgt aufgrund unserem berechtigten
          Interesse gemäß Art. 6, Abs. 1, lit. f DSGVO.
        </p>
        <p>Dementsprechend betreiben wir eine Facebook-Seite.</p>
        <h3>EMPFÄNGER DER PERSONENBEZOGENEN DATEN</h3>
        <p>
          Ihre personenbezogenen Daten werden prinzipiell nicht weitergegeben.
          Unter Umständen werden die personenbezogenen Daten
        </p>
        <ul>
          <li>
            innerhalb des Betriebes unter Berücksichtigung der zulässigen
            Verwendungszwecke,
          </li>
          <li>
            an folgende Auftragsverarbeiter, sowie an Dritte, die für die
            Erbringung der von Ihnen gewünschten Dienstleistungen beigezogen
            werden müssen oder für Fachdienstleistungen in Anspruch genommen
            werden:
            <ul>
              <li>HGV Hoteliers- und Gastwirteverband, Bozen</li>
              <li>IT-Spezialist für die Fernwartung des Hotelprogramms</li>
            </ul>
            übermittelt
          </li>
        </ul>
        <p>
          Ihre Daten können unter anderem aus Buchungsportalen stammen, mit
          denen wir zusammenarbeiten.
        </p>
        <h3>RECHTE DES BETROFFENEN</h3>
        <p>
          <em>Recht auf Auskunft</em>: Sie sind gemäß Art. 15 DSGVO berechtigt
          Auskunft zu erhalten, welche personenbezogenen Daten verarbeitet
          werden, woher diese stammen, um welche Daten es sich handelt, ob diese
          weitergegeben werden und an wen, und was mit den Daten gemacht wird.
        </p>
        <p>
          <em>Recht auf Berichtigung und Löschung</em>: Gemäß Art. 16 DSGVO
          können Sie jederzeit die Berichtigung oder Vervollständigung der
          personenbezogenen Daten verlangen. Ebenso haben Sie im Sinne des Art.
          17 DSGVO das Recht auf Löschung. Insbesondere bei Daten, welche
          veröffentlicht wurden, haben Sie auch das „Recht auf Vergessenwerden“,
          d.h. es werden die notwendigen Schritte unternommen, um alle Links,
          Kopien, Replikationen oder ähnliches zu löschen.
        </p>
        <p>
          <em>Widerspruchsrecht</em>: Sie können jederzeit von Ihrem
          Widerspruchsrecht gemäß Art. 21 DSGVO Gebrauch machen und die erteilte
          Einwilligungserklärung mit Wirkung für die Zukunft abändern oder
          gänzlich widerrufen.
        </p>
        <p>
          <em>Recht auf Einschränkung (oder auch Sperrung)</em>: Sie können im
          Sinne des Art. 18 DSGVO auch die Einschränkung der Verarbeitung der
          personenbezogenen Daten verlangen. Die erhobenen Daten dürfen dann nur
          mit einer individuellen Einwilligung verarbeitet werden oder die
          Verarbeitung muss dann konkret eingeschränkt werden.
        </p>
        <p>
          <em>Recht auf Datenübertragbarkeit</em>: Gemäß Art. 20 DSGVO haben Sie
          das Recht die bereitgestellten personenbezogenen Daten in einem
          strukturierten, gängigen, maschinenlesbaren Format übermittelt zu
          erhalten und die Übertragung Ihrer personenbezogenen Daten auf einen
          anderen Verantwortlichen zu verlangen.
        </p>
        <p>
          Auch ein <em>Recht auf Beschwerde</em> bei der staatlichen
          Aufsichtsbehörde ist gemäß Art. 77 DSGVO vorgesehen.
        </p>
      </>
    ),
  },
  it: {
    title: "Privacy",
    content: (site: APISite, accommodation: Partial<Accommodation>) => (
      <>
        <h2>
          Informazioni ai fini dell’elaborazione di dati personali dell’ospite
        </h2>
        <p>
          Dichiarazione rilasciata ai sensi dell’art. 13 del Regolamento (UE)
          2016/679 del Parlamento Europeo e del Consiglio del 27 aprile 2016
          relativo alla protezione delle persone fisiche con riguardo al
          trattamento dei dati personali, nonché alla libera circolazione di
          tali dati e che abroga la direttiva 95/46/CE (Regolamento generale
          sulla protezione dei dati)
        </p>
        <p>
          Ai sensi del Regolamento generale UE sulla protezione dei dati
          (Regolamento 2016/679 del Parlamento Europeo e del Consiglio) Vi
          informiamo di quanto segue:
        </p>
        <h3>NOME E CONTATTI DEL TITOLARE DEL TRATTAMENTO</h3>
        <p>
          Titolare del trattamento dei dati è {site.legalRepresentative},{" "}
          {accommodation.name}, {accommodation.street}, {accommodation.zip}{" "}
          {accommodation.city} – Italia, tel. {accommodation.phone}, e-mail:{" "}
          {email(accommodation.email)}, pagina internet:{" "}
          {getPrimaryDomain(site)}
        </p>
        <h3>DATI PERSONALI E CATEGORIE PARTICOLARI DI DATI</h3>
        <p>Si considerano Vostri dati personali in particolare</p>
        <ul>
          <li>
            i dati relativi alla Vostra persona (cognome, nome, indirizzo,
            email, numero di telefono, numero di fax, data di nascita) nonché
            lingua e targa del veicolo
          </li>
          <li>
            i dati contenuti nel Vostro passaporto o nella Vostra carta di
            identità
          </li>
          <li>
            i dati relativi alle modalità di pagamento o correlati con i
            pagamenti (per es. coordinate bancarie, estremi delle carte di
            credito)
          </li>
          <li>la data di arrivo e la durata del soggiorno</li>
          <li>i dati di accesso, utilizzo e comunicazione</li>
        </ul>
        <p>
          Rientrano nelle categorie particolari i dati di natura sanitaria e
          quelli relativi a particolari esigenze personali.
        </p>
        <h3>
          FINALITÀ DEL TRATTAMENTO DEI DATI PERSONALI E BASE GIURIDICA DELLA
          LORO ELABORAZIONE
        </h3>
        <p>I dati comunicati verranno elaborati per le seguenti finalità:</p>
        <ul>
          <li>adempimento di obblighi di legge</li>
          <li>
            attuazione di interventi precontrattuali e contrattuali relativi al
            soggiorno dell’ospite (prenotazione, soggiorno, partenza ecc.)
          </li>
          <li>inoltro di comunicazioni e telefonate dirette all’ospite</li>
          <li>
            memorizzazione dei dati ai fini di agevolare future prenotazioni e
            richieste
          </li>
          <li>
            invio di informazioni, offerte e novità dell’hotel e degli altri
            esercizi partner dello stesso mediante email, posta ed altri mezzi
            di comunicazione, con riferimento all’hotel, al territorio, agli
            eventi locali ecc.
          </li>
          <li>
            dati di accesso, utilizzo e comunicazione, elaborati per motivi di
            sicurezza e per garantire la funzionalità della pagina Internet.
          </li>
        </ul>
        <p>Basi giuridiche per il trattamento dei Vostri dati sono:</p>
        <ul>
          <li>
            obblighi giuridici o di legge (art. 6, comma 1, lett. c) RGPD);
          </li>
          <li>
            adempimento di obblighi e interventi precontrattuali e contrattuali
            (art. 6, comma ,1 lett. b) RGPD);
          </li>
          <li>il Vostro consenso (art. 6, comma 1, lett. a) RGPD);</li>
          <li>
            il perseguimento del legittimo interesse (art. 6, comma 1, lett. f)
            RGPD);
          </li>
        </ul>
        <p>
          Sulla nostra pagina internet non vengono trattate categorie
          particolari di dati personali.
        </p>
        <p>
          Se durante la permanenza presso {accommodation.name} devono essere
          trattate categorie particolari di dati personali è necessario il
          Vostro consenso.
        </p>
        <h3>Prenota</h3>
        <p>
          L’indicazione dei Vostri dati personali nel relativo modulo di
          prenotazione sulla nostra pagina internet è necessario per effettuare
          una prenotazione e per usufruire dei servizi contrattuali. I dati di
          viaggio contrassegnati con asterisco devono essere indicati per
          effettuare una prenotazione.
        </p>
        <p>
          Usiamo una piattaforma di pagamento per garantire il pagamento sicuro
          con la Vostra carta di credito.
        </p>
        <p>
          Selezionando la caparra con bonifico bancario vengono trattati
          ulteriori dati personali, come titolare del conto e IBAN.
        </p>
        <p>
          L’indicazione dei dati relativi alla fattura è facoltativa, ma è
          necessaria per consentire l’emissione di una fattura.
        </p>
        <p>
          La base giuridica per il trattamento dei dati personali è
          l’adempimento di obblighi e interventi precontrattuali e contrattuali.
        </p>
        <p>
          La durata della conservazione dei dati viene stabilita in base agli
          obblighi di conservazione dei dati e ai doveri previsti dalla legge e
          i Vostri dati personali per la prenotazione vengono conservati per il
          periodo strettamente necessario per il trattamento.
        </p>
        <p>
          I dati elaborati in base al Vostro consenso resteranno conservati fino
          a revoca.
        </p>
        <h3>Richiedi</h3>
        <p>
          L’indicazione dei Vostri dati personali nel relativo modulo di
          richiesta sulla nostra pagina internet è necessaria per fare una
          richiesta e per usufruire dei servizi precontrattuali e contrattuali.
          I dati di viaggio contrassegnati con asterisco devono essere indicati
          per poter effettuare una richiesta.
        </p>
        <p>
          La base giuridica per il trattamento dei Vostri dati personali è
          l’adempimento di obblighi e interventi precontrattuali e contrattuali.
        </p>
        <p>
          La durata della conservazione dei dati viene stabilita in base agli
          obblighi di conservazione dei dati e ai doveri previsti dalla legge e
          i Vostri dati personali per la richiesta vengono conservati per il
          periodo strettamente necessario per il trattamento.
        </p>
        <h3>Invio di newsletter e messaggi pubblicitari</h3>
        <p>
          L’indicazione dei dati personali per l’invio di informazioni, offerte
          e novità della struttura ricettiva è facoltativa. In assenza
          dell’indicazione dei dati personali non riceverete offerte e novità. I
          dati da Voi indicati al fine di ricevere informazioni, offerte e
          novità, i quali vengono trattati in base al Vostro consenso,
          resteranno conservati fino a revoca.
        </p>
        <h3>Dati di accesso, utilizzo e comunicazione</h3>
        <h4>Cookie</h4>
        <h4>Cosa sono i cookie?</h4>
        <p>
          I cookie sono piccoli file di testo che vengono memorizzati dal
          browser dell’utente quando quest’ultimo visita un sito web. Quando una
          pagina viene visualizzata di nuovo sullo stesso dispositivo, il cookie
          viene inviato al sito web o trasmesso ad un altro sito a cui
          appartiene. Il sito web riconosce quindi che la pagina è già stata
          visualizzata con questo browser e in tal modo può migliorare
          l’esperienza di navigazione dell’utente quando la pagina verrà
          visualizzata di nuovo. I cookie memorizzano ad esempio le preferenze
          dell’utente e adattano le offerte visualizzate alle esigenze e agli
          interessi di quello specifico utente. In base al loro funzionamento e
          alla loro finalità di utilizzo, i cookie si dividono in varie
          categorie:
        </p>
        <ul>
          <li>Cookie tecnici</li>
          <li>Cookie di profilazione</li>
          <li>Cookie di terze parti</li>
        </ul>
        <h4>Cookie tecnici</h4>
        <p>
          Questi cookie vengono memorizzati nel dispositivo dell’utente solo
          durante l’effettiva sessione e servono per poter utilizzare
          correttamente il sito web. I cookie analitici, utilizzati per
          l’elaborazione delle statistiche di accesso, rientrano nella categoria
          dei cookie tecnici. Essi raccolgono informazioni generali senza
          identificare l’utente.
        </p>
        <h4>Cookie di profilazione</h4>
        <p>
          Questi cookie vengono utilizzati per identificare il comportamento di
          navigazione dell’utente, comprese le sue preferenze, abitudini, ecc.
          In questo modo è possibile inviare all’utente messaggi pubblicitari
          collegati a prodotti visualizzati in precedenza o simili a questi.
        </p>
        <h4>Cookie di terze parti</h4>
        <p>
          Cookie di terze parti sono cookie di altri siti web. Chiudendo il
          banner informativo o cliccando qualunque elemento del sito acconsente
          all’uso dei cookie.
        </p>
        <h4>Quali cookie vengono usati su questo sito?</h4>
        <p>
          Su questo sito vengono utilizzati cookie tecnici e cookie di terze
          parti
        </p>
        <h4>Come disabilitare i cookie</h4>
        <p>
          Le norme sulla protezione dei dati personali prevedono che l’utente
          possa disabilitare cookie già installati. Nei link sottostanti ci sono
          le spiegazioni su come è possibile disattivare l’utilizzo dei cookie
          nei diversi browser.
        </p>
        <ul>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?hl=it">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">
              Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/it-it/HT201265">Safari</a>
          </li>
          <li>
            <a href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
              Microsoft Edge
            </a>
          </li>
        </ul>
        <p>
          In caso di utilizzo di un browser diverso dai sopra elencati è
          possibile disattivare l’utilizzo dei cookie seguendo le istruzioni nel
          menu d’aiuto del proprio browser (inserendo la parola “cookie” nella
          ricerca del menu). L’utente potrà quindi disabilitare e/o cancellare i
          cookie mediante le relative impostazioni del proprio browser e
          disabilitare e/o cancellare i singoli cookie non “tecnici” installati
          da parte terzi accedendo al sito web gestito da European Interactive
          Digital Advertising Alliance (EDAA) all’indirizzo:{" "}
          <a href="https://www.youronlinechoices.eu">
            www.youronlinechoices.eu
          </a>
        </p>
        <p>
          <a href="#uc-corner-modal-show">Qui</a> è possibile modificare le
          impostazioni dei cookie.
        </p>

        <h4>Archivi del Server-Log</h4>
        <p>
          Utilizzando la pagina internet vengono rilevati automaticamente e
          conservati dati negli archivi del server-log. Si tratta nella
          fattispecie dei seguenti dati: indirizzo IP, versione del browser e
          browser, sistema operativo, ora dell’accesso e quantità di dati
          traferiti.
        </p>
        <h4>Google Analytics</h4>
        <p>
          La nostra pagina internet utilizza le funzioni del servizio di analisi
          web di Google Analytics di Google LLC, 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA per ottimizzare la nostra offerta online.
          In questo contesto i dati possono essere traferiti a paesi terzi.
          L’accordo privacy shield in base al quale Google è certificata,
          garantisce la conformità alla legge europea sulla protezione dei dati.
        </p>
        <h4>Google Maps</h4>
        <p>
          Utilizziamo le mappe del servizio “Google Maps” del fornitore Google.
          Tra i dati trattati possono rientrare l’indirizzo IP e i dati di
          posizione degli utenti, questi tuttavia non vengono rilevati senza il
          Vostro consenso (attenzione alle impostazioni del Vostro dispositivo
          mobile).
        </p>
        <h4>Codifica SSL e base giuridica</h4>
        <p>
          Codifica SSL: Al fine di garantire la sicurezza dei Vostri dati
          all’atto della loro trasmissione ci serviamo di procedure di
          codificazione via https rispondenti allo stato d’arte.
        </p>
        <p>
          La memorizzazione ed elaborazione dei dati di accesso, utilizzo e
          comunicazione vengono effettuate per motivi di sicurezza e per
          consentire la funzionalità della pagina internet. Base giuridica è qui
          il perseguimento del legittimo interesse ai sensi dell’art. 6, c. 1,
          lett. f) RGPD.
        </p>
        <h4>Social Media</h4>
        <p>
          Per comunicare ed informare i clienti e potenziali clienti siamo
          presenti sui social network. In questo contesto i dati degli utenti
          possono essere trattati anche al di fuori dell’Unione Euorpea.
          Tuttavia è disponibile una decisione di adeguatezza della Commissione
          Europea ai sensi dell’art. 45 RGPD, ossia garanzie adeguate ai sensi
          dell’art. 46 RGPD relativamente al trasferimento dei dati.
        </p>
        <p>
          La base giuridica per il trattamento dei dati in merito alla visita
          delle nostre presenze sui social media è il perseguimento del
          legittimo interesse ai sensi dell’art. 6, c. 1, lett. f) RGPD.
        </p>
        <p>In conformità a questo gestiamo un sito facebook.</p>
        <h3>DESTINATARI DEI DATI PERSONALI</h3>
        <p>
          In linea di principio, i Vostri dati personali non verranno inoltrati
          ad altri soggetti. In casi particolari essi potranno tuttavia
        </p>
        <ul>
          <li>
            venir inoltrati ad altri reparti dell’hotel, nei limiti del
            legittimo utilizzo,
          </li>
          <li>
            venir inoltrati ai seguenti responsabili del trattamento, come pure
            a terzi soggetti, ove ciò sia necessario per venire incontro alle
            Vostre richieste o per l’effettuazione di servizi particolari:
            <ul>
              <li>HGV – Unione Albergatori e Pubblici Esercenti</li>
              <li>
                Specialista IT per la manutenzione a distanza del programma
                alberghiero
              </li>
            </ul>
          </li>
        </ul>
        <p>
          I vostri dati personali possono avere origine tra l’altro da portali
          di prenotazione con i quali collaboriamo.
        </p>
        <h3>DIRITTI DELL’INTERESSATO</h3>
        <p>
          <em>Diritto all’accesso</em>: in base all’art. 15 del RGPD, Lei ha
          diritto ad essere informato sui dati personali trattati, sulla loro
          provenienza, la loro natura, il loro eventuale inoltro e a chi e il
          loro utilizzo.
        </p>
        <p>
          <em>Diritto alla rettifica e alla cancellazione</em>: in base all’art.
          16 RGPD, Lei ha in qualsiasi momento il diritto di chiedere la
          rettifica o l’integrazione dei Suoi dati personali. Del pari in base
          all’art. 17 RGPD Lei può chiedere la cancellazione degli stessi. In
          particolare in presenza di dati pubblicati Le compete anche il
          „diritto all’oblio“, ossia chiedere che vengano adottate le misure
          necessarie a cancellare tutti i link, le copie, le repliche o simili.
        </p>
        <p>
          <em>Diritto di opposizione</em>: Lei può in ogni momento esercitare il
          diritto di opposizione di cui all’art. 21 RGPD e modificare o revocare
          interamente, con effetto futuro, il consenso impartito.
        </p>
        <p>
          <em>Diritto di limitazione (o di blocco) del trattamento</em>: in base
          all’art. 18 RGPD Lei può esigere anche la limitazione del trattamento
          dei Suoi dati personali. I dati rilevati potranno venir elaborati solo
          previo specifico consenso, ovvero la loro elaborazione andrà
          concretamente limitata.
        </p>
        <p>
          <em>Diritto alla portabilità dei dati</em>: in base all’art. 20 RGPD
          Lei ha il diritto di farsi trasmettere i dati personali da Lei forniti
          in un formato strutturato, di uso comune e leggibile da dispositivo
          automatico, come pure di esigere la loro trasmissione ad un altro
          responsabile.
        </p>
        <p>
          In base all’art. 77 RGPD è previsto anche un suo{" "}
          <em>diritto di reclamo</em> presso l’autorità di controllo.
        </p>
      </>
    ),
  },
  en: {
    title: "Privacy",
    content: (site: APISite, accommodation: Partial<Accommodation>) => (
      <>
        <h2>
          Advisory Notice concerning the processing of guests' personal data
        </h2>
        <p>
          Declaration issued pursuant to art. 13 of EU Regulation 2016/679
          enacted by the European Parliament and by the Council on 27 April,
          2016 relative to the protection of natural persons as regards the
          processing of personal data and the free movement of such data, which
          also supersedes directive 95/46/EC (General Data Protection
          Regulation).
        </p>
        <p>
          Pursuant to the EU Regulation for the protection of personal data
          (Regulation 2016/679 enacted by the European Parliament and by the
          Council), please be informed as follows:
        </p>
        <h3>NAME AND CONTACT DETAILS OF THE DATA CONTROLLER</h3>
        <p>
          The controller of the data processing is {site.legalRepresentative},{" "}
          {accommodation.name}, {accommodation.street}, {accommodation.zip}{" "}
          {accommodation.city} – Italy, tel. {accommodation.phone}, e-mail:{" "}
          {email(accommodation.email)}, internet: {getPrimaryDomain(site)}
        </p>
        <h3>PERSONAL DATA AND SPECIAL CATEGORIES OF DATA</h3>
        <p>The following are considered as being your personal data:</p>
        <ul>
          <li>
            data relative to you as an individual (surname, name, address,
            e-mail, telephone number, telefax number, date of birth), as well as
            language and vehicle license plate number.
          </li>
          <li>the data contained in your passport or identity card</li>
          <li>
            data relative to the methods of payment or relating to payments
            (e.g. banking information, details of credit cards).
          </li>
          <li>date of arrival and duration of visit.</li>
          <li>data relative to access, utilization and communication.</li>
        </ul>
        <p>
          Special categories of data include data relative to health care and to
          special personal needs.
        </p>
        <h3>
          PURPOSE OF PROCESSING OF THE PERSONAL DATA AND LEGAL GROUND FOR
          PROCESSING
        </h3>
        <p>The data provided will be processed for the following purposes:</p>
        <ul>
          <li>compliance with the requirements of the law;</li>
          <li>
            fulfilment of pre-contractual and contractual obligations relative
            to the guest's visit (booking, accommodation, departure etc.);
          </li>
          <li>
            sending of communication and telephone contact with the guest;
          </li>
          <li>
            storage of data in order to facilitate subsequent bookings and
            requests;
          </li>
          <li>
            transmission of information, offers and news regarding the hotel and
            its partners, by e-mail, letter and other means of communication
            relative to the hotels, the local area and any local events, etc.;
          </li>
          <li>
            data regarding access, utilization and communication, which are
            processed for security reasons and to guarantee correct functioning
            of the Internet page.
          </li>
        </ul>
        <p>Legal grounds for the processing of your data include:</p>
        <ul>
          <li>
            compliance with the requirements of the law (art. 6, section 1,
            para. c) of the RGPD);
          </li>
          <li>
            fulfilment of pre-contractual and contractual obligations (art. 6,
            section 1, para. b) of the RGPD);
          </li>
          <li>your consent (art. 6, section 1, para. a) of the RGPD);</li>
          <li>
            for legitimate interests (art. 6, section 1, para. f) of the RGPD).
          </li>
        </ul>
        <p>
          Special categories of personal data are not processed on our internet
          page.
        </p>
        <p>
          Your consent is necessary if special categories of personal data are
          processed during the course of your visit to {accommodation.name}.
        </p>
        <h3>Booking</h3>
        <p>
          It is necessary to enter your personal data on the booking form
          included in our web site in order to make a booking and to make use of
          the contractual services. The travel data marked with an asterisk must
          be entered when booking your stay.
        </p>
        <p>
          We utilize a payment platform which guarantees the security of any
          payments made using your credit card.
        </p>
        <p>
          When you elect to pay the deposit by bank transfer, additional
          personal data such as the name of the account-holder and bank
          coordinates, too, will be processed.
        </p>
        <p>
          Indication of the data relative to the invoice is not obligatory, but
          is necessary for issuing an invoice.
        </p>
        <p>
          The legal ground for the processing of personal data is compliance
          with the law and fulfilment of pre-contractual and contractual
          obligations.
        </p>
        <p>
          The period for which the data will be stored is in accordance with the
          obligations for the storage of data and the requirements of the law;
          your personal data provided for the purposes of booking will be stored
          only for the period strictly necessary for processing.
        </p>
        <p>
          Data processed subject to your consent will be stored until you
          withdraw such consent.
        </p>
        <h3>Request</h3>
        <p>
          The indication of your personal data on the relative request form
          included in our internet page is necessary for your request and to
          allow you to make use of the pre-contractual and contractual services.
          The travel data marked with an asterisk must be entered when entering
          a request.
        </p>
        <p>
          The legal ground for the processing of personal data is compliance
          with the law and fulfilment of pre-contractual and contractual
          obligations.
        </p>
        <p>
          The period for which the data will be stored is in accordance with the
          obligations for the storage of data and the requirements of the law;
          your personal data provided for the purposes of your request will be
          stored only for the period strictly necessary for processing.
        </p>
        <h3>Newsletters and promotional messages</h3>
        <p>
          Indication of your personal data for the sending by the hotel of
          information, special offers and newsletters is not obligatory. If you
          do not submit your personal data, you will not receive special offers
          and newsletters.
        </p>
        <p>
          Personal data provided in order to receive information, special offers
          and newsletters will be processed only with your consent, and will be
          stored until you withdraw such consent.
        </p>
        <h3>Data relative to access, utilization and communication</h3>
        <h4>Cookies</h4>
        <p>What are cookies?</p>
        <p>
          Cookies are small text files which are stored by the user's browser
          when the user visits a web site. When a page is visited again using
          the same device, the cookie is sent to the web site or transmitted to
          another related site. In this way the web site recognizes that the
          page has already been visited using this browser, thus improving the
          user's browsing experience when the same page is accessed again.
          Cookies store the user's preferences and adapt the offers displayed to
          the preferences and interests of that specific user. Cookies fall into
          different categories according to their operation and their purpose:
        </p>
        <ul>
          <li>Technical cookies</li>
          <li>Profiling cookies</li>
          <li>Third-party cookies</li>
        </ul>
        <h4>Technical cookies</h4>
        <p>
          These cookies are stored on the user's device only during the session
          itself and are used to ensure that the web site functions correctly.
          Analytical cookies, which are utilized for processing statistical
          access data, are classified as technical cookies. These collect
          general information without any identification of the client.
        </p>
        <h4>Profiling cookies</h4>
        <p>
          Profiling cookies are used to identify the way in which the user
          navigates the site, including their preferences, etc., in order to
          send promotional messages relative to products previously viewed or
          similar products.
        </p>
        <h4>Third-party cookies</h4>
        <p>
          Third-party cookies are those featured by other web sites. By closing
          the information banner or clicking on any section of the site, the
          user consents to the use of these cookies.
        </p>
        <h4>Which cookies are used on this web site?</h4>
        <p>This site uses technical and third-party cookies.</p>
        <h4>How to disable cookies</h4>
        <p>
          The norms governing the protection of personal data state that the
          user can disable any cookies that have been installed. The links shown
          below contain instructions for deactivating the use of cookies on the
          following browsers:
        </p>
        <ul>
          <li>
            <a href="https://support.google.com/accounts/answer/61416?hl=en">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
              Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/en-gb/HT201265">Safari</a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-gb/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
              Microsoft Edge
            </a>
          </li>
        </ul>
        <p>
          If browsers other than those listed above are used, the user can
          disable the use of cookies by following the instructions in the help
          menu of the browser used (by inserting the word "cookie" in the search
          box in the menu). The user can then disable and/or delete the cookies
          using the settings of their browser as well as disable and/or delete
          any non-technical cookies installed by third parties by accessing the
          site managed by the European Interactive Digital Advertising Alliance
          (EDAA) at:{" "}
          <a href="https://www.youronlinechoices.eu/">
            www.youronlinechoices.eu
          </a>
          .
        </p>
        <p>
          <a href="#uc-corner-modal-show">Here</a> you can adjust your cookie
          settings.
        </p>

        <h4>Server Log Archives</h4>
        <p>
          When the web page is accessed, data are automatically identified and
          stored in the archives of the server log. In this case, these data
          include: IP address, browser and browser version, operating system,
          time of access and quantity of data transferred.
        </p>
        <h4>Google Analytics</h4>
        <p>
          Our web page also utilizes the functions provided by the web analysis
          service of Google Analytics developed by Google LLC, 1600 Amphitheatre
          Parkway, Mountain View, CA 94043, USA in order to optimise our online
          service. To this end, the data may be transferred to third countries.
          The Privacy Shield framework, which has certified Google, ensures that
          the protection of personal data is in compliance with European laws.
        </p>
        <h4>Google Maps</h4>
        <p>
          We utilize the maps provided by Google's mapping service "Google
          Maps". The data processed may include the user's IP address and
          position, though these will not be identified without your consent
          (check the settings on your mobile device).
        </p>
        <h4>SSL protocol and legal ground</h4>
        <p>
          SSL protocol (Secure Sockets Layer): In order to guarantee the
          security of your data during transmission, we use state-of-the-art
          coding procedures via https.
        </p>
        <p>
          Data regarding access, utilization and communication are stored and
          processed for reasons of security and to ensure the correct operation
          of the internet page. The legal ground is the pursuit of the
          legitimate interests in accordance with art. 6, section 1, para. f) of
          the RGPD.
        </p>
        <h4>Social Media</h4>
        <p>
          We are present on the social networks with the purpose of providing
          information to and communicating with clients and potential clients.
          As a result, the user's data may also be processed outside the
          European Union. However, a European Union adequacy decision is in
          place as set forth in art. 45 of the RGPD which provides adequate
          guarantees relative to the transfer of data in accordance with art. 46
          of the RGPD.
        </p>
        <p>
          The legal ground for the processing of the data relative to your visit
          to our social media platforms is the pursuit of legitimate interests
          in accordance with art. 6, section 1, para. f) of the RGPD.
        </p>
        <p>To this end, we maintain sites on facebook.</p>
        <h4>recipients of personal data</h4>
        <p>
          In principle, your personal data will not be transferred to other
          subjects. However, the data may in special cases be:
        </p>
        <ul>
          <li>
            transferred to other departments of the hotel, though subject to the
            limits of legitimate use;
          </li>
          <li>
            transferred to the following data processors or to third subjects,
            in those cases where it is necessary to meet your requests or to
            provide special services:
          </li>
          <li>HGV – Association of Hoteliers and Caterers</li>
          <li>
            IT specialist for the remote maintenance of the hotel programme.
          </li>
        </ul>
        <p>Your personal data may also be collected from booking portals.</p>
        <h4>rights of the data subject</h4>
        <p>
          <em>Right of access</em>: as set forth in art. 15 of the RGPD, you
          shall have the right to be informed of the personal data processed,
          the source and nature of the data, the transfer of the data (if any)
          and the recipient, and the manner in which the data are utilized.
        </p>
        <p>
          <em>Right of rectification and erasure</em>: as set forth in art. 16
          of the RGPD, you may at any time request the rectification or
          completion of your personal data. Similarly, as set forth in art. 17
          of the RGPD, you may request the erasure of your personal data. In the
          case of published data, you shall also enjoy the "right to be
          forgotten", in other words request the enactment of the measures
          necessary to delete all the links, copies, replications etc.
        </p>
        <p>
          <em>Right to object</em>: you may at any time exercise the right to
          object as set forth in art. 21 of the RGPD and modify or entirely
          withdraw your consent to any future processing.
        </p>
        <p>
          <em>Right to restriction (or cessation) of the processing</em>: as set
          forth in art. 18 of the RGPD, you may also require that the processing
          of your personal data be restricted. In this case, the data may be
          processed only subject to specific consent, therefore the processing
          of the data will be effectively restricted.
        </p>
        <p>
          <em>Right to data portability</em>: as set forth in art. 20 of the
          RGPD, you shall enjoy the right to receive the data provided by you in
          a structured, commonly-used and machine-readable format, and may also
          require the transmission of said data to a different Data Processor.
        </p>
        <p>
          As set forth in art. 77 of the RGPD, you shall enjoy the right to{" "}
          <em>lodge a complaint</em> with a supervisory authority.
        </p>
      </>
    ),
  },
};

const PrivacyModule: FunctionComponent<Props & StateProps> = ({
  site,
  accommodation,
  scheme,
  i18n,
  translatedModule: module,
  pageId,
  translatedModule: {
    id,
    settings: { textAlign },
    translation: {
      settings: { description },
      languageId,
    },
  },
  isPreview,
}) => (
  <ModuleWithHeadings
    title={i18n.title}
    subtitle={undefined}
    id={id}
    className="PrivacyModule TextModule"
    colors={{
      background: scheme.main.background,
      color: scheme.main.text,
    }}
  >
    <div className="TextModule__Wrapper Module__Wrapper Module__Wrapper--default-padding">
      <div
        className="TextModule__Content TextAlign--left"
        onClick={(event) => {
          // Handle clicks on the Usercentrics show modal link. This implementation
          // uses the `.closest` DOM method because an `onClick` prop directly on the
          // link would be lost as soon as the privacy text is edited.
          const { target } = event;
          if (!(target instanceof Element)) return;

          const isClickOnUsercentricsModalShowLink = Boolean(
            target.closest('[href="#uc-corner-modal-show"]')
          );
          if (!isClickOnUsercentricsModalShowLink) return;
          event.preventDefault();
          const { UC_UI } = window as unknown as WindowState;
          UC_UI?.showSecondLayer();
        }}
      >
        <ModuleHeadings
          textAlign={{ title: "left", subtitle: "left" }}
          isFirstOnPage={true}
          scheme={scheme}
          subtitle={undefined}
          title={i18n.title}
        />

        {!description && (
          <section
            className={`Module__BodyText RichTextCopySource TextAlign--${textAlign.description}`}
          >
            {i18n.content(site, accommodation)}
          </section>
        )}
        {description && (
          <RichEditorWrapper
            pageId={pageId}
            languageId={languageId}
            module={module}
            isPreview={isPreview}
            scheme={scheme}
            className={`TextAlign--${textAlign.description}`}
          />
        )}
      </div>
    </div>
  </ModuleWithHeadings>
);

const mapStateToProps = (
  { sites, accommodation, colorSchemes }: StoreState,
  {
    translatedModule,
    translatedModule: {
      translation: { languageId },
    },
  }: Props
): StateProps => {
  const site = getActiveSite(sites);

  return {
    site,
    accommodation: accommodation[languageId] || {},
    scheme: getActiveColorScheme(colorSchemes, site, translatedModule),
    i18n: getTranslations(languageId, translations),
  };
};

export default connect(mapStateToProps)(PrivacyModule);
