import { ComponentType, HTMLProps, forwardRef } from "react";
import {
  BaseModuleProps,
  ContainerQueries,
  Language,
  Module,
  ModuleSettingsTypes,
  ModuleSortInsertPosition,
  TranslatedModule,
  TranslatedPage,
} from "../types/index.js";
import { allModulesByType } from "../utils/allModulesByType.js";
import { getTranslatedModule, getTranslationLanguage } from "../utils/utils.js";
import ModuleWrapper from "./ModuleWrapper.js";

interface Props {
  activeId: string | undefined;
  divProps?: HTMLProps<HTMLDivElement>;
  index: number;
  languageId: Language;
  module: Module<ModuleSettingsTypes>;
  onModuleSelect: (
    translatedModule: TranslatedModule,
    languageId: Language
  ) => void;
  page: TranslatedPage;
  queries: ContainerQueries;
  isDragging: boolean;
  insertPosition: ModuleSortInsertPosition | undefined;
}

const PageModule = forwardRef<HTMLDivElement, Props>(
  (
    {
      activeId,
      divProps,
      index,
      languageId,
      module,
      onModuleSelect,
      page,
      queries,
      isDragging,
      insertPosition,
    },
    ref
  ) => {
    const { id, type, pageId } = module;
    const isActive = id === activeId;
    const targetLang = getTranslationLanguage(module, languageId);
    const translatedModule = getTranslatedModule(module, targetLang);
    const isUntranslated = languageId !== targetLang;
    const CurrentModule: ComponentType<BaseModuleProps> =
      allModulesByType[type];

    return (
      <ModuleWrapper
        id={id}
        key={id + languageId}
        onSelect={() => onModuleSelect(translatedModule, languageId)}
        isActive={isActive}
        moduleType={translatedModule.type}
        isUntranslated={isUntranslated}
        ref={ref}
        divProps={divProps}
        isDragging={isDragging}
        insertPosition={insertPosition}
      >
        <CurrentModule
          translatedModule={translatedModule}
          pageId={page.id}
          queries={queries}
          isPreview={true}
          isActive={isActive}
          isFirstOnPage={index === 0 && pageId !== null}
          activeModuleId={activeId}
        />
      </ModuleWrapper>
    );
  }
);

export default PageModule;
