import ClassNames from "classnames";
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { fitRectWidth } from "../utils/utils.js";
import FormInfo from "./FormInfo.js";

interface Props {
  onReset: () => void;
  onSubmit: () => void;
  closeURL: string;
  helpText: ReactNode;
  isImageLoaded: boolean;
  children: ReactNode;
}

const ImageDetailSettingsFrame: FunctionComponent<Props> = ({
  onReset,
  onSubmit,
  closeURL,
  helpText,
  isImageLoaded,
  children,
}) => {
  const [canvasWidth, setCanvasWidth] = useState<number>();
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<ResizeObserver>();
  const rafIdRef = useRef<number>();

  useEffect(() => {
    // Resize the image to fit its container.
    // Unfortunately this doesn’t seem possible with CSS only.
    if (!isImageLoaded) return;
    observerRef.current = new ResizeObserver((entries) => {
      rafIdRef.current = window.requestAnimationFrame(() => {
        const size = entries[0]?.borderBoxSize[0];
        if (!size || !canvasRef.current) return;
        const width = fitImageWidth(size, canvasRef.current);
        setCanvasWidth(width);
      });
    });
    containerRef.current && observerRef.current.observe(containerRef.current);
  }, [isImageLoaded]);

  useEffect(
    () => () => {
      observerRef.current?.disconnect();
      rafIdRef.current !== undefined &&
        window.cancelAnimationFrame(rafIdRef.current);
    },
    []
  );

  return (
    <div className="ImageDetailSettings">
      <div ref={containerRef} className="ImageDetailSettings__Inner">
        <div
          ref={canvasRef}
          className={ClassNames("ImageDetailSettings__Canvas", {
            "ImageDetailSettings__Canvas--visible": canvasWidth !== undefined,
          })}
          style={{ width: canvasWidth ?? "auto" }}
        >
          {children}
        </div>
      </div>

      <FormInfo className="ImageDetailSettings__Help">{helpText}</FormInfo>

      <div className="ImageDetailSettings__Actions">
        <button
          className="Btn Btn--action"
          onClick={() => onSubmit()}
          type="button"
        >
          Übernehmen
        </button>
        <button className="Btn" onClick={() => onReset()} type="button">
          Zurücksetzen
        </button>
        <Link to={closeURL} className="Btn">
          Schließen
        </Link>
      </div>
    </div>
  );
};

const fitImageWidth = (
  size: ResizeObserverSize,
  canvasEl: HTMLDivElement
): number => {
  const canvasRect = canvasEl.getBoundingClientRect();

  const currentCanvasSize = {
    width: canvasRect.width,
    height: canvasRect.height,
  };

  const containerSize = {
    width: size.inlineSize,
    height: size.blockSize,
  };

  return fitRectWidth(currentCanvasSize, containerSize);
};

export default ImageDetailSettingsFrame;
