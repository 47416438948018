import { FunctionComponent } from "react";

interface Props {
  heading: string;
  src: string;
}

const Tutorial: FunctionComponent<Props> = ({ heading, src }) => {
  return (
    <article className="Tutorial">
      <video className="Tutorial__Video" width={1920} height={1080} controls>
        <source src={src} type="video/mp4" />
      </video>
      <h3 className="Tutorial__Heading">{heading}</h3>
    </article>
  );
};

export default Tutorial;
