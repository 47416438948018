import { FunctionComponent, useState } from "react";
import ReactCrop, { PercentCrop } from "react-image-crop";
import { defaultImageCropParams } from "../reducers/modules/byId.js";
import { getPictureWithSizedURL } from "../selectors/pictures.js";
import { ImageCropParams, Picture } from "../types/index.js";
import { round } from "../utils/utils.js";
import ImageDetailSettingsFrame from "./ImageDetailSettingsFrame.js";

interface Props {
  picture: Picture;
  closeURL: string;
  initialCrop: ImageCropParams;
  onSubmit: (crop: ImageCropParams) => void;
}

const ImageCrop: FunctionComponent<Props> = ({
  picture,
  closeURL,
  initialCrop,
  onSubmit,
}) => {
  const [crop, setCrop] = useState<PercentCrop | undefined>(
    convertToLibraryCrop(initialCrop)
  );
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <ImageDetailSettingsFrame
      closeURL={closeURL}
      helpText={
        <>
          Ziehen Sie den gewünschten Ausschnitt mit der Maus auf oder verändern
          Sie den bestehenden Ausschnitt. Achtung: Das Festlegen des Ausschnitts
          setzt den ausgewählten wichtigsten Punkt des Bilds auf die Mitte
          zurück.
        </>
      }
      onReset={() => setCrop(undefined)}
      isImageLoaded={isImageLoaded}
      onSubmit={() => onSubmit(convertToSettingsCrop(crop))}
    >
      <ReactCrop
        crop={crop}
        onChange={(_, percentageCrop) => setCrop(percentageCrop)}
      >
        <img
          src={getPictureWithSizedURL({ width: 1920 })(picture).url}
          alt={picture.title ?? ""}
          onLoad={() => {
            setIsImageLoaded(true);
          }}
        />
      </ReactCrop>
    </ImageDetailSettingsFrame>
  );
};

const convertToLibraryCrop = (
  crop: ImageCropParams
): PercentCrop | undefined => {
  if (crop.width === 1 && crop.height === 1) {
    return undefined;
  }

  return {
    unit: "%",
    x: crop.x * 100,
    y: crop.y * 100,
    width: crop.width * 100,
    height: crop.height * 100,
  };
};

const convertToSettingsCrop = (
  crop: PercentCrop | undefined
): ImageCropParams => {
  if (!crop?.height || !crop.width) {
    return defaultImageCropParams;
  }

  return {
    x: round((crop.x ?? 0) / 100, 4),
    y: round((crop.y ?? 0) / 100, 4),
    width: round(crop.width / 100, 4),
    height: round(crop.height / 100, 4),
  };
};

export default ImageCrop;
