import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { APISite } from "../../server/types/index.js";
import {
  deleteColorScheme,
  duplicateColorScheme,
} from "../actions/ColorSchemes.js";
import { getFonts } from "../actions/Fonts.js";
import { patchSite } from "../actions/Sites.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  ColorSchemes as ColorSchemeTypes,
  Fonts,
  Language,
  StoreState,
  ThreeSizes,
} from "../types/index.js";
import { getURL, threeSizesSelectOptions } from "../utils/utils.js";
import AccommodationLogos from "./AccommodationLogos.js";
import Checkbox from "./Checkbox.js";
import ColorScheme from "./ColorScheme.js";
import Favicon from "./Favicon.js";
import FontSelect from "./FontSelect.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import SelectFormField from "./SelectFormField.js";
import Sidebar from "./Sidebar.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
  colorSchemeId: string;
}>;

interface StateProps {
  siteId: string;
  pageId: string;
  fonts: Fonts;
  schemes: ColorSchemeTypes;
  site: APISite;
}

type ReduxProps = ConnectedProps<typeof connector>;

const DesignSettings: FunctionComponent<Props & ReduxProps> = ({
  getFonts,
  history,
  siteId,
  pageId,
  match: {
    params: { languageId },
  },
  deleteColorScheme,
  patchSite,
  duplicateColorScheme,
  schemes,
  fonts,
  site,
}) => {
  useEffect(() => {
    getFonts();
  }, []);

  const handleColorSchemeAdd = () => {
    const id = crypto.randomUUID();
    handleColorSchemeEdit(id);
  };

  const handleColorSchemeEdit = (id: string) => {
    history.push(
      getURL(siteId, "pages", pageId, languageId, "design", "color-schemes", id)
    );
  };

  const handleColorSchemeDelete = (id: string) => {
    deleteColorScheme(siteId, id);
  };

  const handleColorSchemeActive = (id: string) => {
    patchSite(siteId, {
      colorSchemeId: id,
    });
  };

  const handleDuplicate = (id: string) => {
    duplicateColorScheme(siteId, id);
  };

  const handleActiveFont = (fontFamily: string) => {
    patchSite(siteId, { fontFamily });
  };

  const handleActiveTitleFont = (titleFontFamily: string | null) => {
    patchSite(siteId, { titleFontFamily });
  };

  return (
    <Sidebar className="Design__Navigation Form" heading="Design">
      <section className="Sidebar__Section">
        <div className="ColorSchemes">
          <FormInfo>
            Das hier ausgewählte Farbschema wird standardmäßig auf alle Module
            angewendet.
          </FormInfo>
          {Object.keys(schemes).map((id) => (
            <ColorScheme
              key={id}
              id={id}
              scheme={schemes[id]}
              onDelete={handleColorSchemeDelete}
              onEdit={handleColorSchemeEdit}
              onDuplicate={handleDuplicate}
              onSelection={handleColorSchemeActive}
              isActive={id === site.colorSchemeId}
              isDefaultScheme={false}
            />
          ))}
          <button
            className="Btn Btn--action ColorSchemes__Add"
            onClick={handleColorSchemeAdd}
          >
            <Icon glyph="add" /> Neues Farbschema
          </button>
        </div>
      </section>

      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Schrift-Einstellungen</h3>
        <Checkbox
          checkedStatus={site.titleFontForSubtitle}
          htmlId="title-font-for-subtitle"
          text="Titel-Schriftart für Untertitel"
          onChange={(value) =>
            patchSite(siteId, { titleFontForSubtitle: value })
          }
        />
        <SelectFormField<ThreeSizes>
          label="Schriftgröße Titel/Untertitel"
          onChange={(value) => patchSite(siteId, { titleFontSize: value })}
          options={threeSizesSelectOptions}
          value={site.titleFontSize}
        />

        <SelectFormField<ThreeSizes>
          label="Schriftgröße Textkörper"
          onChange={(value) => patchSite(siteId, { fontSize: value })}
          options={threeSizesSelectOptions}
          value={site.fontSize}
        />
      </section>

      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Schriftarten</h3>
        <FontSelect
          fonts={fonts}
          onSelection={handleActiveFont}
          onTitleFontSelection={handleActiveTitleFont}
          family={site.fontFamily}
          titleFamily={site.titleFontFamily}
        />
      </section>

      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Favicon</h3>
        <Favicon />
      </section>

      <section className="Sidebar__Section">
        <h3 className="Sidebar__Section__Title">Betriebslogo</h3>
        <AccommodationLogos languageId={languageId} />
      </section>
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites, fonts, colorSchemes: { schemes } },
  {
    match: {
      params: { siteId, pageId },
    },
  }
): StateProps => ({
  siteId,
  pageId,
  fonts,
  schemes,
  site: getActiveSite(sites),
});

const mapDispatchToProps = {
  getFonts,
  patchSite,
  deleteColorScheme,
  duplicateColorScheme,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(DesignSettings));
