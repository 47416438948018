import { FunctionComponent, ReactNode } from "react";
import FontSelectItem from "./FontSelectItem.js";
import FormInfo from "./FormInfo.js";

interface Props {
  activeFamily: string | null;
  families: string[];
  listName: string;
  label: string;
  onSelection: (family: string) => void;
  description?: string;
  children?: ReactNode;
}

const FontSelectList: FunctionComponent<Props> = ({
  activeFamily,
  families,
  listName,
  label,
  onSelection,
  children,
  description,
}) => (
  <div className="Form__Field">
    <div className="Form__Content FontSelectList">
      {children}
      {families.map((family) => (
        <FontSelectItem
          key={family}
          family={family}
          onSelection={onSelection}
          isActive={activeFamily === family}
          listName={listName}
        />
      ))}
    </div>
    {description && <FormInfo>{description}</FormInfo>}
    <div className="Form__Label">
      <label>{label}</label>
    </div>
  </div>
);

export default FontSelectList;
