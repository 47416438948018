import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { SeparatorIconName, SeparatorIconType } from "../types/index.js";
import { iconsById } from "../utils/data.js";
import { keys } from "../utils/utils.js";
import Icon from "./Icon.js";
import SeparatorIcon from "./SeparatorIcon.js";

export const getShowIconForAccommodation = (
  icon: SeparatorIconType,
  accommodationId: number
): boolean =>
  icon.accommodationId === undefined ||
  icon.accommodationId === accommodationId;

export const icons: SeparatorIconType[] = keys(iconsById).map(
  (id) => iconsById[id]
);

const getTotalIconsCount = (accommodationId: number): number =>
  icons.filter((icon) => getShowIconForAccommodation(icon, accommodationId))
    .length;

interface Props {
  selectedIcon: SeparatorIconName | null;
  libraryLink: string;
  onRemove: () => void;
  accommodationId: number;
}

const IconSelect: FunctionComponent<Props> = ({
  selectedIcon,
  libraryLink,
  onRemove,
  accommodationId,
}) => {
  const hasSelectedIcon = selectedIcon !== null;

  return (
    <div className="IconSelect Form__Field">
      <div className="Form__Content IconSelect__Content">
        <div className="IconSelect__List">
          <Link
            to={libraryLink}
            className={ClassNames("IconSelect__Item", {
              "IconSelect__Item--selected": hasSelectedIcon,
            })}
          >
            {selectedIcon && (
              <SeparatorIcon
                className="IconSelect__Icon"
                glyph={selectedIcon}
              />
            )}

            {hasSelectedIcon && (
              <div className="IconSelect__Check">
                <Icon glyph="check" />
              </div>
            )}
          </Link>
          {hasSelectedIcon && (
            <button
              className="Btn IconSelect__Remove"
              type="button"
              onClick={onRemove}
            >
              <Icon glyph="delete" />
            </button>
          )}
          <Link
            to={libraryLink}
            className="IconSelect__Item IconSelect__Item--count"
          >
            +{getTotalIconsCount(accommodationId)}
          </Link>
        </div>
      </div>
      <div className="Form__Label">
        <label>Icon-Auswahl</label>
      </div>
    </div>
  );
};

export default IconSelect;
