import ClassNames from "classnames";
import { FunctionComponent } from "react";

interface Props {
  family: string;
  isActive: boolean;
  onSelection: (family: string) => void;
  listName: string;
}

const FontSelectItem: FunctionComponent<Props> = ({
  family,
  onSelection,
  isActive,
  listName,
}) => (
  <label
    className={ClassNames("Btn FontSelectList__Item", {
      "Btn--active": isActive,
    })}
    style={{ fontFamily: family }}
  >
    {family}
    <input
      name={listName}
      checked={isActive}
      type="radio"
      value={family}
      onChange={() => onSelection(family)}
    />
  </label>
);

export default FontSelectItem;
