import { CSSProperties, FunctionComponent, useRef, useState } from "react";
import {
  defaultImageCropParams,
  defaultImportantImagePoint,
} from "../reducers/modules/byId.js";
import { getPictureWithSizedURL } from "../selectors/pictures.js";
import { ImageCropParams, Picture, Point } from "../types/index.js";
import { limit, round } from "../utils/utils.js";
import { getImageBoundingRect } from "./CroppedImage.js";
import Icon from "./Icon.js";
import ImageDetailSettingsFrame from "./ImageDetailSettingsFrame.js";

interface Props {
  closeURL: string;
  picture: Picture;
  crop: ImageCropParams;
  initialPoint: Point | undefined;
  targetAspectRatio: number | undefined;
  onSubmit: (point: Point) => void;
}

const ImagePointSelection: FunctionComponent<Props> = ({
  closeURL,
  picture,
  crop,
  initialPoint,
  targetAspectRatio,
  onSubmit,
}) => {
  const [point, setPoint] = useState<Point>(
    initialPoint ?? defaultImportantImagePoint
  );
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const canvasRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  return (
    <ImageDetailSettingsFrame
      closeURL={closeURL}
      helpText={
        <>
          Klicken Sie auf eine beliebige Stelle im Bild, um den wichtigsten
          Punkt auszuwählen. Dieser wird dann im Bildausschnitt zentriert
          angezeigt.
        </>
      }
      onReset={() => setPoint(defaultImportantImagePoint)}
      onSubmit={() => onSubmit(point)}
      isImageLoaded={isImageLoaded}
    >
      <div
        ref={canvasRef}
        onClick={({ clientX, clientY, currentTarget }) =>
          setPoint(getPoint({ clientX, clientY, target: currentTarget }))
        }
      >
        <img
          ref={imageRef}
          className="FullImage"
          src={getPictureWithSizedURL({ width: 1920, crop })(picture).url}
          alt=""
          onLoad={() => setIsImageLoaded(true)}
        />
        {targetAspectRatio !== undefined && (
          <div
            style={getAspectRatioBoxStyle(
              getAspectRatioBoundingRect({
                crop,
                picture,
                point,
                targetAspectRatio,
              })
            )}
            className="ImageDetailSettings__AspectRatioBox"
          />
        )}
        <div
          className="ImageDetailSettings__Crosshair"
          style={{
            left: point.x * 100 + "%",
            top: point.y * 100 + "%",
          }}
        >
          <Icon
            className="ImageDetailSettings__CrosshairIcon"
            glyph="location"
          />
        </div>
      </div>
    </ImageDetailSettingsFrame>
  );
};

const getPoint = ({
  clientX,
  clientY,
  target,
}: {
  clientX: number;
  clientY: number;
  target: HTMLDivElement;
}): Point => {
  const bounds = target.getBoundingClientRect();

  const x = round((clientX - bounds.left) / bounds.width, 4);
  const y = round((clientY - bounds.top) / bounds.height, 4);

  return {
    x: limit({ value: x, min: 0, max: 1 }),
    y: limit({ value: y, min: 0, max: 1 }),
  };
};

const getAspectRatioBoxStyle = ({
  x,
  y,
  width,
  height,
}: ImageCropParams): CSSProperties => ({
  left: toPercent(x),
  top: toPercent(y),
  width: toPercent(width),
  height: toPercent(height),
});

const toPercent = (value: number): string => round(value * 100, 4) + "%";

const getAspectRatioBoundingRect = ({
  point,
  crop,
  picture,
  targetAspectRatio,
}: {
  point: Point;
  crop: ImageCropParams;
  picture: Picture;
  targetAspectRatio: number;
}): ImageCropParams =>
  getImageBoundingRect({
    detail: { crop: defaultImageCropParams, importantPoint: point },
    originalAspectRatio:
      (picture.width / picture.height) * (crop.width / crop.height),
    targetAspectRatio,
  });

export default ImagePointSelection;
