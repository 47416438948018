import { FunctionComponent } from "react";
import { CheckStatus } from "../types/index.js";
import { formatDateTime } from "../utils/utils.js";
import Icon from "./Icon.js";
import Spinner from "./Spinner.js";

interface Props {
  onStartClick: () => void;
  currentStatus: CheckStatus;
  issuesCount: number;
  checkedAt: Date | null;
  lastCheckCaption: string;
  noIssuesMessage: string;
  solveIssuesMessage: string;
  entitiesTerm: string;
}

const IssuesDashboard: FunctionComponent<Props> = ({
  onStartClick,
  currentStatus,
  issuesCount,
  checkedAt,
  lastCheckCaption,
  noIssuesMessage,
  solveIssuesMessage,
  entitiesTerm,
}) => {
  const hasFailed = currentStatus === "failed";

  return (
    <div className="IssuesDashboard">
      {currentStatus === "pending" && (
        <div className="IssuesDashboard__Check">
          <div className="IssuesDashboard__Stats">
            <strong>
              Bitte warten <Spinner />
            </strong>
            Die Seite wird auf Fehler überprüft.
          </div>
        </div>
      )}
      {currentStatus !== "pending" && (
        <div className="IssuesDashboard__Check">
          <button
            className="IssuesDashboard__CheckStart Btn Btn--action"
            onClick={onStartClick}
          >
            <Icon glyph="search" /> Neue Überprüfung starten
          </button>
          <div className="IssuesDashboard__Stats">
            <strong>{lastCheckCaption}</strong>
            {hasFailed && (
              <>
                Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später
                noch einmal.
              </>
            )}

            {!hasFailed && checkedAt && formatDateTime(checkedAt)}
            {!hasFailed && !checkedAt && (
              <>Die Seite wurde noch nie auf {entitiesTerm} überprüft.</>
            )}
          </div>
        </div>
      )}

      {currentStatus !== "uninitialized" && issuesCount > 0 && (
        <div className="IssuesDashboard__Stats IssuesDashboard__Stats--error">
          <strong>
            <Icon glyph="warning" /> {issuesCount} {entitiesTerm} gefunden
          </strong>
          {solveIssuesMessage}
        </div>
      )}

      {currentStatus !== "uninitialized" && issuesCount === 0 && (
        <div className="IssuesDashboard__Stats IssuesDashboard__Stats--success">
          <strong>{issuesCount} Fehler gefunden</strong>
          {noIssuesMessage}
        </div>
      )}
    </div>
  );
};

export default IssuesDashboard;
