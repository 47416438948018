import { FunctionComponent } from "react";
import { Revision as RevisionType } from "../types/index.js";
import Revision from "./Revision.js";

interface Props {
  title: string;
  revisions: RevisionType[];
  isFavoriteList: boolean;
  onRevert: (id: string) => void;
  onToggleFavoriteStatus: (id: string, isFavorite: boolean) => void;
  maxFavoritesReached: boolean;
}

const RevisionsList: FunctionComponent<Props> = ({
  revisions,
  isFavoriteList,
  onRevert,
  onToggleFavoriteStatus,
  title,
  maxFavoritesReached,
}) => {
  const hasRevisions = !!revisions.length;

  return (
    <section className="Sidebar__Section">
      <h3 className="Sidebar__Section__Title">{title}</h3>
      {!hasRevisions && !isFavoriteList && (
        <p>noch keine Veröffentlichungen vorhanden</p>
      )}
      {!hasRevisions && isFavoriteList && <p>keine Favoriten vorhanden</p>}
      {hasRevisions && (
        <ul className="List RevisionsList">
          {revisions.map((revision) => {
            return (
              <Revision
                key={revision.id}
                revision={revision}
                onRevert={onRevert}
                onToggleFavoriteStatus={onToggleFavoriteStatus}
                maxFavoritesReached={maxFavoritesReached}
              />
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default RevisionsList;
