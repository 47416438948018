import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  deleteModuleTranslation,
  postModule,
  setModuleSettings,
} from "../actions/Modules.js";
import { defaultImportantImagePoint } from "../reducers/modules/byId.js";
import { getPictureById } from "../selectors/pictures.js";
import {
  ImageModuleIdsByPictureId,
  ImageModuleSettings,
  Language,
  Module,
  ModuleType,
  PartialModuleSettings,
  Picture,
  PostModuleParams,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import {
  creatableGlobalModules,
  filterItemWithTitle,
  getImageModuleIdsByPictureId,
  getModulesByParentId,
  getShortId,
  getURL,
} from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";
import MediaLibraryImage from "./MediaLibraryImage.js";
import PictureGroupsList from "./PictureGroups.js";
import Sidebar from "./Sidebar.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  moduleId: string;
  languageId: Language;
}>;

interface StateProps {
  media: Picture[];
  selectedImages: ImageModuleIdsByPictureId;
  singleSelectedModuleId: string | undefined;
  pageId: string | null;
}

type ReduxProps = ConnectedProps<typeof connector>;

const onSelection = (
  {
    selectedImages,
    postImageModule,
    deleteModuleTranslation,
    match: {
      params: { siteId, languageId, moduleId },
    },
    singleSelectedModuleId,
    setModuleSettings,
    pageId,
  }: Props & ReduxProps,
  pictureId: string
) => {
  const imageModuleId = selectedImages[pictureId] as string | undefined;

  if (singleSelectedModuleId && !imageModuleId) {
    setModuleSettings(singleSelectedModuleId, {
      global: {
        pictureId,
        importantPoint: defaultImportantImagePoint,
      },
    });
    return;
  }

  if (imageModuleId) {
    deleteModuleTranslation({
      siteId,
      pageId,
      languageId,
      moduleId: imageModuleId,
      deleteAllTranslations: true,
    });
    return;
  }

  postImageModule({
    moduleId: getShortId(),
    siteId,
    pageId,
    moduleType: "ImageModule",
    parentId: moduleId,
    languageIds: [languageId],
    settings: {
      global: {
        pictureId,
      },
    },
    next: undefined,
  });
};

const ViewSelectButton: FunctionComponent<{
  label: string;
  isActive: boolean;
  onSelect: () => void;
}> = ({ isActive, onSelect, label }) => (
  <label className={ClassNames("Btn", { "Btn--active": isActive })}>
    {label}
    <input
      type="radio"
      onChange={onSelect}
      name="media-library-view"
      checked={isActive}
    />
  </label>
);

const MediaLibrary: FunctionComponent<Props & ReduxProps> = (props) => {
  const {
    media,
    selectedImages,
    match: {
      params: { siteId, languageId, moduleId, pageId: pageIdFromParams },
    },
  } = props;

  const [filterText, setFilterText] = useState("");
  const [groupsViewActive, setGroupsViewActive] = useState(false);
  const rafId = useRef<number>();
  const showGroupsView = groupsViewActive && !filterText;

  useEffect(() => {
    return () => {
      rafId.current !== undefined && window.cancelAnimationFrame(rafId.current);
    };
  });

  const closeLink = getURL(
    siteId,
    "pages",
    pageIdFromParams,
    languageId,
    "modules",
    moduleId
  );

  const filteredMedia = media.filter((picture) =>
    filterItemWithTitle(picture, filterText)
  );

  return (
    <Sidebar
      className="MediaLibrary"
      heading="Medienbibliothek"
      closeLink={closeLink}
    >
      <form className="Form" onSubmit={(e) => e.preventDefault()}>
        <div className="Form__Field">
          <input
            type="search"
            value={filterText}
            onChange={({ target: { value } }) => {
              rafId.current = window.requestAnimationFrame(() =>
                setFilterText(value)
              );
            }}
          />
          <div className="Form__Label">
            <label>Suche</label>
          </div>
          {!filteredMedia.length && <FormInfo>Keine Medien gefunden!</FormInfo>}
        </div>
      </form>

      <div className="BtnGroup BtnGroup--full-width">
        <ViewSelectButton
          isActive={!showGroupsView}
          label="Alle Bilder"
          onSelect={() => {
            setGroupsViewActive(false);
          }}
        />
        <ViewSelectButton
          isActive={showGroupsView}
          label="Bildergruppen"
          onSelect={() => {
            setGroupsViewActive(true);
            setFilterText("");
          }}
        />
      </div>

      {showGroupsView && (
        <PictureGroupsList
          selectedImages={selectedImages}
          onSelection={(pictureId) => onSelection(props, pictureId)}
          languageId={languageId}
        />
      )}

      {!showGroupsView && (
        <div className="MediaLibrary__AllPictures">
          <div className="MediaLibrary__Images">
            {filteredMedia.map(({ id: pictureId, url, title }) => (
              <MediaLibraryImage
                key={pictureId}
                url={url}
                title={title}
                isSelected={!!selectedImages[pictureId]}
                onSelection={() => onSelection(props, pictureId)}
                width={256}
                height={128}
              />
            ))}
          </div>
        </div>
      )}

      <Link className="Btn MediaLibrary__Button" to={closeLink}>
        <Icon glyph="arrow-left" /> Zurück
      </Link>
    </Sidebar>
  );
};

const isCategoryAllowed = (picture: Picture) =>
  ["logo", "pictures"].includes(picture.category);

const modulesWithSingleImage: ModuleType[] = [
  "TextModule",
  "HighlightModule",
  "PopUpModule",
  "QuoteModule",
];

const hasMultipleImages = (moduleType: ModuleType) =>
  !modulesWithSingleImage.includes(moduleType);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, mediaLibrary: { pictures } },
  {
    match: {
      params: { moduleId, pageId: pageIdFromParams },
    },
  }
): StateProps => {
  const currentModule = modules.byId[moduleId] as Module | undefined;

  const allowMultiple = currentModule
    ? hasMultipleImages(currentModule.type)
    : true;
  const isCreatableGlobalModule = currentModule
    ? creatableGlobalModules.includes(currentModule.type)
    : false;
  const pageId = isCreatableGlobalModule ? null : pageIdFromParams;

  const imageModules = getModulesByParentId<ImageModuleSettings>(
    modules,
    moduleId,
    pageId,
    "ImageModule"
  );

  const singleSelectedModuleId =
    imageModules.length && !allowMultiple ? imageModules[0]?.id : undefined;

  const selectedImages = getImageModuleIdsByPictureId({
    modules,
    moduleId,
    pageId,
  });

  const media = Object.entries(pictures)
    .map(([pictureId, picture]) =>
      getPictureById(
        pictures,
        pictureId,
        // Don’t append size parameters to SVG images
        picture.url.endsWith(".svg")
          ? undefined
          : {
              width: 256,
              height: 128,
              mode: 1,
            }
      )
    )
    .filter(isCategoryAllowed);

  return {
    media,
    selectedImages,
    singleSelectedModuleId,
    pageId,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { siteId, languageId },
    },
  }: Props
) => ({
  postImageModule: (params: PostModuleParams<ImageModuleSettings>) =>
    dispatch(postModule<ImageModuleSettings>(params)),
  deleteModuleTranslation: (
    ...params: Parameters<typeof deleteModuleTranslation>
  ) => dispatch(deleteModuleTranslation(...params)),
  setModuleSettings: (
    moduleId: string,
    settings: PartialModuleSettings<ImageModuleSettings>
  ) =>
    dispatch(
      setModuleSettings<ImageModuleSettings>(
        siteId,
        languageId,
        moduleId,
        settings
      )
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MediaLibrary);
