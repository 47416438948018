import { FunctionComponent, memo, useState } from "react";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import { Captions } from "yet-another-react-lightbox/plugins";
import i18n from "../i18n/index.js";
import {
  defaultImageCropParams,
  defaultImportantImagePoint,
} from "../reducers/modules/byId.js";
import { getPictureWithSizedURL } from "../selectors/pictures.js";
import { AspectRatio, Language, LoadStatus, Picture } from "../types/index.js";
import { getTranslations, keys } from "../utils/utils.js";
import CroppedImage from "./CroppedImage.js";
import ToggleShowBox from "./ToggleShowBox.js";

interface PicturesByCopyright {
  [copyright: string]: Picture[];
}

interface PicturesForCopyright {
  copyright: string;
  startIndex: number;
  pictures: Picture[];
}

interface Props {
  pictures: Picture[];
  onToggleOpen: () => void;
  loadStatus: LoadStatus;
  languageId: Language;
}

/**
 * Warning: this is a memoized component because { pictures }
 * won’t change after the component is mounted
 */
const ImagesCopyright: FunctionComponent<Props> = ({
  pictures,
  onToggleOpen,
  loadStatus,
  languageId,
}) => {
  const trans = getTranslations(languageId, i18n).imprint;
  const copyrightPictures = getCopyrightPictures(pictures);
  const slides = getSlides(pictures);
  const hasPictures = !!pictures.length;
  const aspectRatio: AspectRatio = 1.5;
  const [openIndex, setOpenIndex] = useState(-1);

  return (
    <ToggleShowBox
      className="ImagesCopyright Module__BodyText"
      title={trans.pictures}
      onToggleOpen={onToggleOpen}
      loadStatus={loadStatus}
      languageId={languageId}
    >
      {hasPictures && (
        <ul className="ImagesCopyright__List">
          {copyrightPictures.map(({ copyright, pictures, startIndex }) => (
            <li className="ImagesCopyright__ListItem" key={copyright}>
              {copyright}:
              <ul className="ImagesCopyright__PictureList">
                {pictures.map((picture, index) => (
                  <li
                    key={picture.id}
                    className="ImagesCopyright__PictureListItem"
                  >
                    <a
                      href={picture.url}
                      title={picture.title ?? undefined}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenIndex(startIndex + index);
                      }}
                    >
                      <CroppedImage
                        aspectRatio={aspectRatio}
                        width={120}
                        lazyLoad={true}
                        picture={picture}
                        detail={{
                          crop: defaultImageCropParams,
                          importantPoint: defaultImportantImagePoint,
                        }}
                        sizes="120px"
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
      <Lightbox
        open={openIndex >= 0}
        index={openIndex}
        close={() => setOpenIndex(-1)}
        slides={slides}
        plugins={[Captions]}
        animation={{ navigation: 0 }}
      />
    </ToggleShowBox>
  );
};

const getCopyrightPictures = (pictures: Picture[]): PicturesForCopyright[] => {
  const picturesByCopyright = pictures.reduce<PicturesByCopyright>(
    (acc, picture) => {
      const { copyright } = picture;
      acc[copyright] = [...(acc[copyright] || []), picture];
      return acc;
    },
    {}
  );

  const copyrights = keys(picturesByCopyright);

  const copyrightPictures: PicturesForCopyright[] = copyrights.reduce<
    PicturesForCopyright[]
  >((acc, copyright, index) => {
    const pictures = picturesByCopyright[copyright];
    const lastItem = acc[index - 1] as PicturesForCopyright | undefined;

    const current: PicturesForCopyright = {
      copyright,
      pictures,
      startIndex: lastItem ? lastItem.startIndex + lastItem.pictures.length : 0,
    };

    return [...acc, current];
  }, []);

  return copyrightPictures;
};

const getSlides = (pictures: Picture[]): SlideImage[] =>
  pictures
    .map(
      getPictureWithSizedURL({
        height: 1080,
        width: 1920,
        mode: 4,
      })
    )
    .map<SlideImage>(({ url, title, copyright }) => ({
      src: url,
      width: 1920,
      height: 1080,
      alt: title ?? "",
      description: `${title || ""} ${title ? "(" : ""}© ${copyright}${
        title ? ")" : ""
      }`,
    }));

export default memo(ImagesCopyright);
