import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  siteId?: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  override readonly state: State = {
    hasError: false,
  };

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    console.error(error, errorInfo);
  }

  override render() {
    const { siteId = "" } = this.props;
    if (this.state.hasError) {
      return (
        <div className="Admin__Error">
          <h1>Oh nein!</h1>
          <p>
            Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie, die Seite
            neu zu laden.
          </p>
          <a href={`/${siteId}`}>Seite neu laden</a>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
