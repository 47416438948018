import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { SiteLanguage } from "../../server/types/index.js";
import { Language, TranslatedPage } from "../types/index.js";
import { getURL } from "../utils/utils.js";

interface Props {
  languages: SiteLanguage[];
  page: TranslatedPage;
  activeLanguage: Language;
  segment?: string;
}

const LanguageNav: FunctionComponent<Props> = ({
  page,
  languages,
  segment = "",
  activeLanguage,
}) => {
  return (
    <ul className="LanguageNav Tabs">
      {languages.map(({ id: lang }) => {
        const isEmpty = page.languages.indexOf(lang) === -1;

        const classNames = ClassNames("Tabs__Item", {
          "Tabs__Item--active": lang === activeLanguage,
          "Tabs__Item--empty": isEmpty,
        });

        const lastSegment = isEmpty ? lang : `${lang}/${segment}`;

        return (
          <li className={classNames} key={lang}>
            <Link to={getURL(page.siteId, "pages", page.id, lastSegment)}>
              {lang}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default LanguageNav;
