import { FunctionComponent, useEffect, useRef } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { removeAlert } from "../actions/Alerts.js";
import { getColorSchemes } from "../actions/ColorSchemes.js";
import { getFonts } from "../actions/Fonts.js";
import { getIssuesCheck } from "../actions/Issues.js";
import { getAllMedia } from "../actions/MediaLibrary.js";
import { getModules, getSiteModules } from "../actions/Modules.js";
import { getPages } from "../actions/Pages.js";
import { getPartners } from "../actions/Partners.js";
import { getSite } from "../actions/Sites.js";
import { logoutUser } from "../actions/User.js";
import { getActiveSite } from "../selectors/sites.js";
import "../stylesheets/admin.scss";
import {
  Alert,
  Language,
  Page as PageType,
  StoreState,
  User,
} from "../types/index.js";
import {
  getMainPageURL,
  getURL,
  isAuthenticated,
  keys,
} from "../utils/utils.js";
import AdminNavItem from "./AdminNavItem.js";
import Alerts from "./Alerts.js";
import ColorSchemeSettings from "./ColorSchemeSettings.js";
import DesignSettings from "./DesignSettings.js";
import ErrorBoundary from "./ErrorBoundary.js";
import Help from "./Help.js";
import ImageDetailSettings from "./ImageDetailSettings.js";
import Issues from "./Issues.js";
import Page from "./Page.js";
import PageNew from "./PageNew.js";
import PageTranslationUpdate from "./PageTranslationUpdate.js";
import PopUpsSettings from "./PopUpsSettings.js";
import PublishSettings from "./PublishSettings.js";
import Revisions from "./Revisions.js";
import SeoSettings from "./SeoSettings.js";
import SiteSettings from "./SiteSettings.js";
import Sites from "./Sites.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId?: string;
  languageId?: Language;
}>;

interface StateProps {
  siteId: string;
  page?: PageType;
  alerts: Alert[];
  user: User;
  siteIsLoaded: boolean;
  aliasPageIds: string[] | undefined;
  languageId: Language | undefined;
  mainPageURL: string | undefined;
  issuesCount: number;
}

type ReduxProps = ConnectedProps<typeof connector>;

const Admin: FunctionComponent<Props & ReduxProps> = ({
  getSite,
  getColorSchemes,
  getPages,
  getSiteModules,
  getAllMedia,
  getPartners,
  getModules,
  siteId,
  aliasPageIds,
  getFonts,
  getIssuesCheck,
  logoutUser,
  page,
  siteIsLoaded,
  alerts,
  removeAlert,
  user,
  languageId,
  mainPageURL,
  issuesCount,
  history,
  location,
}) => {
  const logoutInitiatedRef = useRef(false);

  const handleMountOrUpdate = () => {
    getSite(siteId);
    getColorSchemes(siteId);
    getPages(siteId);
    getSiteModules(siteId);
    getAllMedia(siteId);
    getPartners(siteId);
    getIssuesCheck({ siteId, forceUpdate: false, showLastResult: false });
    getFonts();

    // Load the modules of all alias pages, otherwise they can’t be shown in page
    // on one page websites
    if (aliasPageIds) {
      for (const pageId of aliasPageIds) {
        getModules({ siteId, pageId, forceLoad: false });
      }
    }
  };

  const handleLogout = () => {
    logoutInitiatedRef.current = true;
    logoutUser();
  };

  useEffect(() => {
    // Don’t make the API calls after a logout
    // is triggered, otherwise it would
    // load the data into the store after
    // the logout reducer clears it
    !logoutInitiatedRef.current && handleMountOrUpdate();
  });

  const pageUrl =
    page && languageId && getURL(siteId, "pages", page.id, languageId);

  if (!isAuthenticated(user)) {
    history.replace({
      pathname: getURL("login"),
      // Only redirect to current URL after login
      // if the user was unauthenticated before.
      search: logoutInitiatedRef.current
        ? undefined
        : new URLSearchParams({
            redirect: location.pathname + location.search + location.hash,
          }).toString(),
    });
    return null;
  }

  return (
    <div className="Admin">
      <aside className="Admin__Sidebar">
        <div className="Admin__Sidebar__Inner">
          <nav className="AdminNav">
            <ul className="AdminNav__Container">
              <AdminNavItem
                to={getURL(siteId, "publish")}
                icon="publish"
                isHighlighted={true}
                title="Veröffentlichen"
              />
              <AdminNavItem
                icon="apps"
                to={getURL(siteId, "sites")}
                title="Webseiten"
              />
              {pageUrl && (
                <>
                  <AdminNavItem to={pageUrl} icon="page" title="Seiten" />
                  <AdminNavItem
                    to={getURL(pageUrl, "settings")}
                    icon="page-settings"
                    title="Seiteneinstellungen"
                  />
                  <AdminNavItem
                    to={getURL(pageUrl, "modules")}
                    icon="collections"
                    title="Seitenmodule"
                  />
                  <AdminNavItem
                    to={getURL(pageUrl, "pop-ups")}
                    icon="pop-up"
                    title="Pop-ups"
                  />
                  <AdminNavItem
                    to={getURL(pageUrl, "design")}
                    icon="styles"
                    title="Design"
                  />
                  <AdminNavItem
                    to={getURL(siteId, "revisions")}
                    icon="history"
                    title="Versionsverlauf"
                  />
                </>
              )}
              <AdminNavItem
                to={getURL(siteId, "seo")}
                icon="seo"
                title="Suchmaschinenoptimierung"
              />
              <AdminNavItem
                to={getURL(siteId, "issues")}
                icon="error"
                title="Problembehebung"
              >
                {issuesCount > 0 && (
                  <span className="AdminNav__IndicatorNumber">
                    {issuesCount > 99 ? "99+" : issuesCount}
                  </span>
                )}
              </AdminNavItem>
              <AdminNavItem
                to={getURL(siteId, "settings")}
                icon="settings"
                title="Einstellungen"
              />
              <AdminNavItem
                to={getURL(siteId, "help")}
                icon="school"
                title="Anleitung"
              >
                <span className="AdminNav__IndicatorNumber">15</span>
              </AdminNavItem>
              <AdminNavItem
                onClick={handleLogout}
                icon="logout"
                title="Abmelden"
              />
            </ul>
          </nav>
        </div>
        <a
          className="Admin__Sidebar__Logo"
          href="https://www.hgv.it/"
          target="_blank"
          rel="noopener"
        >
          <img
            className="FullImage"
            src="/hgv.svg"
            alt="Hoteliers- und Gastwirteverband"
          />
        </a>
      </aside>
      <div className="Admin__Content">
        {siteIsLoaded && (
          <ErrorBoundary siteId={siteId}>
            <Route
              exact={true}
              path={getURL(":siteId", "sites")}
              component={Sites}
            />
            <Route
              exact={true}
              path={getURL(":siteId", "publish")}
              component={PublishSettings}
            />
            {!pageUrl && mainPageURL && (
              <div className="EmptyModules">
                <div>
                  Diese Seite existiert nicht. Stattdessen die{" "}
                  <Link to={mainPageURL}>Hauptseite</Link> anzeigen.
                </div>
              </div>
            )}
            {pageUrl && (
              <Switch>
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "new"
                  )}
                  component={PageNew}
                />
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "settings"
                  )}
                  component={PageTranslationUpdate}
                />
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "design"
                  )}
                  component={DesignSettings}
                />
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "design",
                    "color-schemes",
                    ":colorSchemeId"
                  )}
                  component={ColorSchemeSettings}
                />
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "modules",
                    ":moduleId",
                    "design",
                    "color-schemes",
                    ":colorSchemeId"
                  )}
                  component={ColorSchemeSettings}
                />
                <Redirect
                  exact={true}
                  from={getURL(":siteId")}
                  to={user.isAdmin ? getURL(siteId, "sites") : pageUrl}
                />
                <Redirect
                  exact={true}
                  from={getURL(":siteId", "pages")}
                  to={pageUrl}
                />
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "pop-ups"
                  )}
                  component={PopUpsSettings}
                />
              </Switch>
            )}
            <Switch>
              <Redirect
                exact={true}
                from={getURL(":siteId", "seo")}
                to={getURL(siteId, "seo", "errors")}
              />
              <Route
                path={getURL(
                  ":siteId",
                  "seo",
                  ":urlType(errors|redirects|archive|import)"
                )}
                component={SeoSettings}
              />
            </Switch>
            <Switch>
              <Redirect
                exact={true}
                from={getURL(":siteId", "issues")}
                to={getURL(siteId, "issues", "critical")}
              />
              <Route
                exact={true}
                path={getURL(
                  ":siteId",
                  "issues",
                  ":urlType(critical|errors|warnings|archive|resolved)"
                )}
                component={Issues}
              />
            </Switch>
            <Route
              exact={true}
              path={getURL(":siteId", "settings")}
              component={SiteSettings}
            />
            <Route
              exact={true}
              path={getURL(":siteId", "revisions")}
              component={Revisions}
            />
            <Route
              exact={true}
              path={getURL(":siteId", "help")}
              component={Help}
            />
            {page && (
              <>
                <Route
                  exact={true}
                  path={getURL(
                    ":siteId",
                    "pages",
                    ":pageId",
                    ":languageId",
                    "modules",
                    ":moduleId",
                    ":mode(crop|point-selection)"
                  )}
                  component={ImageDetailSettings}
                />
                <Switch>
                  <Route
                    path={getURL(
                      ":siteId",
                      "pages",
                      ":pageId",
                      ":languageId",
                      "modules",
                      ":moduleId"
                    )}
                    component={Page}
                  />
                  <Route
                    path={getURL(
                      ":siteId",
                      "pages",
                      ":pageId",
                      ":languageId",
                      ":headerFooterSelected(header|footer)?"
                    )}
                    component={Page}
                  />
                </Switch>
              </>
            )}
          </ErrorBoundary>
        )}
      </div>
      <Alerts alerts={alerts} onClose={removeAlert} />
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  {
    sites,
    pages,
    alerts,
    user,
    loadStates,
    issues: {
      checker: { issues: issuesCount },
    },
  },
  {
    match: {
      params: { siteId, pageId, languageId },
    },
  }
): StateProps => {
  if (!languageId && sites.activeSiteId) {
    languageId = getActiveSite(sites).languages[0].id;
  }

  pageId = pageId || pages.byParentId["null"][0];

  const pagesAreLoaded = loadStates.pages === "loaded";
  const mainPageURL =
    pagesAreLoaded && languageId
      ? getMainPageURL(pages, languageId, true)
      : undefined;

  const aliasPageIds = pagesAreLoaded
    ? keys(pages.byAlias).map((key) => pages.byAlias[key])
    : undefined;

  return {
    siteId,
    siteIsLoaded: !!sites.activeSiteId,
    page: pageId ? pages.byId[pageId] : undefined,
    aliasPageIds,
    languageId,
    alerts,
    user,
    mainPageURL,
    issuesCount,
  };
};

const mapDispatchToProps = {
  getSite,
  getColorSchemes,
  getPages,
  getSiteModules,
  getAllMedia,
  getPartners,
  removeAlert,
  logoutUser,
  getModules,
  getFonts,
  getIssuesCheck,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Admin);
