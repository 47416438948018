import ClassNames from "classnames";
import { FormEventHandler, FunctionComponent, useRef } from "react";
import FormInfo from "./FormInfo.js";

interface Props {
  isImporting: boolean;
  onSubmit: (urls: string[], form: HTMLFormElement) => void;
}

const placeholder = `Beispiele:

/de/sommer.html        
/de/winter.html
`;

const SeoUrlImport: FunctionComponent<Props> = ({ isImporting, onSubmit }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!textareaRef.current) return;

    const urls = textareaRef.current.value
      .split("\n")
      .filter((url) => url !== "");

    onSubmit(urls, e.currentTarget);
  };

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <div
        className={ClassNames("Form__Field", {
          "Form__Field--disabled": isImporting,
        })}
      >
        <textarea
          rows={20}
          ref={textareaRef}
          placeholder={placeholder}
          disabled={isImporting}
        />
        <FormInfo>
          Fügen Sie eine Liste von fehlerhaften Seitenlinks hinzu. (Ein Link pro
          Zeile).
        </FormInfo>
      </div>

      <button type="submit" className="Btn Btn--action" disabled={isImporting}>
        Importieren
      </button>
    </form>
  );
};

export default SeoUrlImport;
