import { FunctionComponent, useEffect, useRef } from "react";
import { Font, Fonts, FontsByUsage } from "../types/index.js";
import { addAndLoadFonts, deleteFonts, getFontFaces } from "../utils/fonts.js";
import { isDefined } from "../utils/utils.js";
import FontSelectItem from "./FontSelectItem.js";
import FontSelectList from "./FontSelectList.js";

interface Props {
  family: string;
  titleFamily: string | null;
  fonts: Fonts;
  onSelection: (family: string) => void;
  onTitleFontSelection: (titleFamily: string | null) => void;
}

const splitByUsage = (fonts: Fonts): FontsByUsage =>
  fonts.allFamilies.reduce<FontsByUsage>(
    (acc, id) => {
      const font = fonts.byFamily[id];
      return font ? { ...acc, [font.usage]: [...acc[font.usage], id] } : acc;
    },
    { main: [], title: [] }
  );

const getPreviewFontFaces = (fonts: Font[]) =>
  fonts
    .map((font) => {
      const newFont = {
        ...font,
        variants: font.variants.filter(
          ({ weight, style, subset }) =>
            weight === "400" && style === "normal" && subset === "latin"
        ),
      };
      return getFontFaces(newFont);
    })
    .flat();

const FontSelect: FunctionComponent<Props> = ({
  fonts,
  family: activeFamily,
  titleFamily: activeTitleFamily,
  onSelection,
  onTitleFontSelection,
}) => {
  const loadedFontsRef = useRef<FontFace[]>([]);

  useEffect(() => {
    if (!fonts.allFamilies.length) return;

    const fontFaces = getPreviewFontFaces(
      Object.values(fonts.byFamily).filter(isDefined)
    );
    deleteFonts(loadedFontsRef.current);
    addAndLoadFonts(fontFaces);
    loadedFontsRef.current = fontFaces;
  }, [fonts]);

  useEffect(() => {
    return () => {
      deleteFonts(loadedFontsRef.current);
      loadedFontsRef.current = [];
    };
  }, [loadedFontsRef]);

  const byUsage = splitByUsage(fonts);

  return (
    <>
      <FontSelectList
        activeFamily={activeFamily}
        families={byUsage.main}
        listName="font-list-main"
        label="Standard"
        onSelection={onSelection}
      />

      <FontSelectList
        activeFamily={activeTitleFamily}
        families={byUsage.title}
        listName="font-list-title"
        label="Titel / Untertitel"
        onSelection={onTitleFontSelection}
      >
        <FontSelectItem
          family={activeFamily}
          onSelection={() => onTitleFontSelection(null)}
          isActive={activeTitleFamily === null}
          listName="font-list-title"
        />
      </FontSelectList>
    </>
  );
};

export default FontSelect;
