import { FunctionComponent, useEffect } from "react";
import { Alert as AlertType } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  alert: AlertType;
  onClose: (id: string) => void;
  autoHideDuration?: number;
  className: string;
}

const Alert: FunctionComponent<Props> = ({
  alert,
  className,
  onClose,
  autoHideDuration,
}) => {
  useEffect(() => {
    autoHideDuration && setTimeout(() => onClose(alert.id), autoHideDuration);
  }, [onClose, autoHideDuration, alert]);

  return (
    <div className={className}>
      {alert.text}{" "}
      <button className="Alert__Close" onClick={() => onClose(alert.id)}>
        <Icon glyph="close" />
      </button>
    </div>
  );
};

export default Alert;
