import { FunctionComponent, ReactNode } from "react";
import { ModalStatus, ModalType } from "../types/index.js";
import Icon from "./Icon.js";
import ModalRoot from "./ModalRoot.js";

interface Props {
  isOpen: boolean;
  onClose: (status: ModalStatus) => void;
  title: string;
  type?: ModalType;
  actions?: ActionButton[];
  children: ReactNode;
}

interface ActionButton {
  title: string;
  onClick?: () => void;
}

const ModalDialog: FunctionComponent<Props> = ({
  children,
  title,
  isOpen,
  onClose,
  actions,
  type = "confirm",
}) => {
  if (!isOpen) return null;

  return (
    <ModalRoot>
      <div className="Modal">
        <div className="Modal__Dialog">
          <button
            className="Btn Modal__CloseBtn"
            onClick={() => onClose("refuted")}
          >
            <Icon glyph="close" />
          </button>
          <div className="Modal__Title">{title}</div>
          <div className="Modal__Content">{children}</div>
          <div className="BtnGroup BtnGroup--stretch Modal__Buttons">
            {!actions && (
              <>
                {type === "confirm" && (
                  <button
                    onClick={() => onClose("confirmed")}
                    className="Btn Btn--action"
                  >
                    Bestätigen
                  </button>
                )}
                <button onClick={() => onClose("refuted")} className="Btn">
                  Abbrechen
                </button>
              </>
            )}
            {actions &&
              actions.map(({ onClick, title }, index) => (
                <button
                  key={index}
                  onClick={() => {
                    onClick && onClick();
                  }}
                  className="Btn"
                >
                  {title}
                </button>
              ))}
          </div>
        </div>
      </div>
    </ModalRoot>
  );
};

export default ModalDialog;
