import { default as classNames, default as ClassNames } from "classnames";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import {
  Language,
  Page,
  PageLimits,
  PageTranslationType,
} from "../types/index.js";
import { getPageTranslationType, getURL } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  page: Page | undefined;
  languageId: Language;
  pageLimits: PageLimits;
  onPageAdd: (pageType: PageTranslationType) => void;
  onPageDelete: () => void;
  onPageEnabledStatusChange: (isEnabled: boolean) => void;
  mainPageId: string;
}

const PageTreeToolbar: FunctionComponent<Props> = ({
  page,
  pageLimits,
  languageId,
  onPageAdd,
  onPageEnabledStatusChange,
  onPageDelete,
  mainPageId,
}) => {
  const trans = page && page.translations[languageId];
  const type = trans && getPageTranslationType(trans.link);

  const isSystemPage = page ? page.isSystemPage : false;
  const isEnabled = page ? page.isEnabled : false;
  // System pages and the main page should not be deleted
  const pageIsDeletable = page && !page.isSystemPage && page.id !== mainPageId;
  const pagesLimitReached = pageLimits.enabled.limitReached;
  const allPagesLimitReached = pageLimits.all.limitReached;
  const disablePageAdd = allPagesLimitReached || isSystemPage;
  const disableActivation =
    (pagesLimitReached && !isEnabled && type === "page") || isSystemPage;

  return (
    <div className="PageTree__Toolbar RichEditor__Toolbar">
      <ul className="RichEditor__Controls">
        <li>
          <button
            className="Btn RichEditor__Btn"
            disabled={disablePageAdd}
            title={classNames({
              "Neue Seite anlegen": !pagesLimitReached,
              "Die maximale Anzahl an Seiten wurde erreicht. Neue Seiten werden deaktiviert angelegt.":
                pagesLimitReached && !allPagesLimitReached,
              "Die maximale Anzahl an Seiten wurde erreicht.":
                allPagesLimitReached,
            })}
            onClick={() => onPageAdd("page")}
          >
            <Icon glyph="add" />
            <Icon glyph="page" />
          </button>
        </li>
        <li>
          <button
            disabled={isSystemPage}
            className="Btn RichEditor__Btn"
            title="Neuen Link anlegen"
            onClick={() => onPageAdd("redirect")}
          >
            <Icon glyph="add" />
            <Icon glyph="redirect" />
          </button>
        </li>
        <li>
          <button
            disabled={isSystemPage}
            className="Btn RichEditor__Btn"
            title="Neuen Ordner anlegen"
            onClick={() => onPageAdd("folder")}
          >
            <Icon glyph="add" />
            <Icon glyph="folder" />
          </button>
        </li>
      </ul>

      {page && trans && (
        <ul className="RichEditor__Controls RichEditor__Controls--right">
          <li>
            <button
              className={ClassNames("Btn RichEditor__Btn", {
                "Btn--active": !isEnabled,
              })}
              onClick={() => onPageEnabledStatusChange(!isEnabled)}
              disabled={disableActivation}
              title={ClassNames({
                "Seite deaktivieren": isEnabled && !isSystemPage,
                "Seite aktivieren":
                  !isEnabled && !pagesLimitReached && !isSystemPage,
                "Systemseiten können nicht deaktiviert werden": isSystemPage,
                "Seitenlimit erreicht. Deaktivieren Sie zuerst eine andere Seite, um diese Seite aktivieren zu können.":
                  pagesLimitReached && !isEnabled,
              })}
            >
              <Icon glyph="page-disabled" />
            </button>
          </li>
          <li>
            <Link
              to={getURL(page.siteId, "pages", page.id, languageId, "settings")}
              className="Btn RichEditor__Btn"
            >
              <Icon glyph="settings" />
            </Link>
          </li>
          <li>
            <button
              className="Btn RichEditor__Btn"
              disabled={!pageIsDeletable}
              onClick={() => onPageDelete()}
            >
              <Icon glyph={pageIsDeletable ? "delete" : "no-delete"} />
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default PageTreeToolbar;
