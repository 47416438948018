import ClassNames from "classnames";
import { FunctionComponent, ReactNode } from "react";

interface Props {
  wrapperClassName: string;
  children: ReactNode;
}

const LoginPage: FunctionComponent<Props> = ({
  children,
  wrapperClassName,
}) => {
  return (
    <div className="Login">
      <div className={ClassNames("Login__Wrapper", wrapperClassName)}>
        {children}
      </div>
      <div className="Login__Background">
        <div className="Login__Slogan">
          Gestalten Sie Ihre eigene individuelle Betriebsseite
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
