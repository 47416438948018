import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { LinkData, SeoUrl, StoreState } from "../types/index.js";
import LinkSelectForm from "./LinkSelectForm.js";
import Sidebar from "./Sidebar.js";

interface Props {
  urlId: string;
  onClose: () => void;
  onSelect: (link: LinkData) => void;
}

interface StateProps {
  seoUrl: SeoUrl | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

const RedirectPageSelection: FunctionComponent<Props & ReduxProps> = ({
  seoUrl,
  onClose,
  onSelect,
}) => {
  if (!seoUrl) return null;
  const { pageTranslationId: id } = seoUrl;

  let link: LinkData = {
    pageId: null,
    languageId: null,
    url: null,
    moduleId: null,
    moduleType: null,
  };

  if (id) {
    link.pageId = id[0];
    link.languageId = id[1];
  }

  return (
    <Sidebar
      className="RedirectPageSelection Sidebar--absolute Sidebar--absolute-right"
      heading="Seite auswählen"
      onClose={onClose}
    >
      <form className="Form">
        <LinkSelectForm
          link={link}
          onLinkChange={onSelect}
          showActionButtons={false}
          allowedLinkTypes={{
            pages: true,
            folders: false,
            external: false,
            modules: false,
          }}
        />
      </form>
    </Sidebar>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { seo: { urls } },
  { urlId }
): StateProps => ({
  seoUrl: urls.byId[urlId],
});

const connector = connect(mapStateToProps);

export default connector(RedirectPageSelection);
