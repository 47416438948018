import { LogoCategory, ThunkAction } from "../types/index.js";
import { ModuleNotFoundError } from "../utils/utils.js";
import { debouncedPatchModuleRequest } from "./Modules.js";

export type Action =
  | {
      type: "MOVE_LOGO_START";
      moduleId: string;
      logoCategory: LogoCategory;
      dragIndex: number;
      hoverIndex: number;
    }
  | {
      type: "UPSERT_LOGO_START";
      moduleId: string;
      logoCategory: LogoCategory;
      newLogoId: string;
      previousLogoId: string | undefined;
    }
  | {
      type: "DELETE_LOGO_START";
      moduleId: string;
      logoCategory: LogoCategory;
      logoId: string;
    };

export const changeLogo = (
  siteId: string,
  action: Action
): ThunkAction<void> => {
  return (dispatch, getState) => {
    dispatch(action);

    const module = getState().modules.byId[action.moduleId];
    if (!module) throw new ModuleNotFoundError(action.moduleId);

    debouncedPatchModuleRequest({
      dispatch,
      getState,
      siteId,
      moduleId: action.moduleId,
      patch: {
        settings: module.settings,
      },
    });
  };
};

export const moveLogoStart = (
  moduleId: string,
  logoCategory: LogoCategory,
  dragIndex: number,
  hoverIndex: number
): Action => ({
  type: "MOVE_LOGO_START",
  moduleId,
  logoCategory,
  dragIndex,
  hoverIndex,
});

export const upsertLogoStart = (
  moduleId: string,
  logoCategory: LogoCategory,
  newLogoId: string,
  previousLogoId: string | undefined
): Action => ({
  type: "UPSERT_LOGO_START",
  moduleId,
  logoCategory,
  newLogoId,
  previousLogoId,
});

export const deleteLogoStart = (
  moduleId: string,
  logoCategory: LogoCategory,
  logoId: string
): Action => ({
  type: "DELETE_LOGO_START",
  moduleId,
  logoCategory,
  logoId,
});
