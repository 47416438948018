import ClassNames from "classnames";
import { FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { IconName } from "../types/index.js";
import Icon from "./Icon.js";

interface Props {
  icon?: IconName;
  heading?: string;
  subheading?: string;
  className?: string;
  children?: ReactNode;
  closeLink?: string;
  onClose?: () => void;
}

const Sidebar: FunctionComponent<Props> = ({
  className,
  heading,
  subheading,
  children,
  closeLink,
  icon,
  onClose,
}) => {
  const hasHeadings = heading || subheading;

  return (
    <aside className={ClassNames("Sidebar", className)}>
      {!!closeLink && (
        <Link className="Sidebar__CloseButton" to={closeLink}>
          <Icon glyph="close" />
        </Link>
      )}
      {!!onClose && (
        <button onClick={onClose} className="Sidebar__CloseButton">
          <Icon glyph="close" />
        </button>
      )}

      <div className="Sidebar__Inner">
        {hasHeadings && (
          <div className="Sidebar__Headings">
            {heading && (
              <h2 className="Sidebar__Heading">
                {icon && <Icon glyph={icon} />}
                {heading}
              </h2>
            )}
            {subheading && (
              <div className="Sidebar__Subheading">{subheading}</div>
            )}
          </div>
        )}
        {children}
      </div>
    </aside>
  );
};

export default Sidebar;
