import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { SeparatorIconName } from "../types/index.js";
import Icon from "./Icon.js";
import SeparatorIcon from "./SeparatorIcon.js";

interface Props {
  id: SeparatorIconName;
  title: string;
  onSelection: () => void;
  isSelected: boolean;
}

const IconLibraryItem: FunctionComponent<Props> = ({
  id,
  title,
  onSelection,
  isSelected,
}) => {
  return (
    <div
      className={ClassNames("MediaLibrary__Image MediaLibrary__Image--light", {
        "MediaLibrary__Image--selected": isSelected,
      })}
      title={title}
      onClick={onSelection}
    >
      <SeparatorIcon className="MediaLibrary__Icon" glyph={id} />
      {isSelected && (
        <div className="MediaLibrary__Image__Check">
          <Icon glyph="check" />
        </div>
      )}
    </div>
  );
};

export default IconLibraryItem;
