import { Component, createRef } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  getSeoCrawler,
  getSeoUrls,
  getSeoUrlsStart,
  patchSeoUrl,
  postSeoCrawler,
  postSeoUrls,
} from "../actions/Seo.js";
import {
  GetSeoUrlsQueryParams,
  LinkData,
  Seo,
  SeoUrlRequest,
  SeoUrlType,
  StoreState,
  ThunkDispatch,
} from "../types/index.js";
import { getURL, parseISO } from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import IssuesDashboard from "./IssuesDashboard.js";
import RedirectPageSelection from "./RedirectPageSelection.js";
import SeoUrl from "./SeoUrl.js";
import SeoUrlImport from "./SeoUrlImport.js";
import Sidebar from "./Sidebar.js";
import Spinner from "./Spinner.js";
import Tab from "./Tab.js";

type Props = RouteComponentProps<{
  siteId: string;
  urlType: SeoUrlType;
}>;

type ReduxProps = ConnectedProps<typeof connector>;

interface StateProps {
  seo: Seo;
  queryParams: GetSeoUrlsQueryParams;
}

interface State {
  activeUrlId: string | undefined;
}

const applyFilter = (urlType: SeoUrlType): GetSeoUrlsQueryParams => {
  switch (urlType) {
    case "archive":
      return {
        archived: true,
      };

    case "redirects":
      return {
        redirected: true,
        archived: false,
      };

    default:
      return {
        redirected: false,
        archived: false,
      };
  }
};

class SeoSettings extends Component<Props & ReduxProps, State> {
  override readonly state: State = {
    activeUrlId: undefined,
  };
  timeout = 0;
  loadingRef = createRef<HTMLDivElement>();

  resetLazyload = () => {
    const el = this.loadingRef.current;
    el?.classList.remove("lazyload", "lazyloaded", "lazyloading");
    el?.classList.add("lazyload");
  };

  loadSeoUrls = async (keepExisting: boolean) => {
    const { match, getSeoUrls, queryParams } = this.props;
    const urls = await getSeoUrls({
      siteId: match.params.siteId,
      params: queryParams,
      keepExisting,
    });

    urls.length && this.resetLazyload();
  };

  loadSeoUrlsOnUnveil = () => {
    this.loadSeoUrls(true);
  };

  clearSeoUrls = () => {
    this.props.clearSeoUrlsAction();
    this.resetLazyload();
  };

  addLoadingListener = () => {
    this.loadingRef.current?.classList.add("lazyload");
    this.loadingRef.current?.addEventListener(
      "lazybeforeunveil",
      this.loadSeoUrlsOnUnveil
    );
  };

  removeLoadingListener = () => {
    this.loadingRef.current?.removeEventListener(
      "lazybeforeunveil",
      this.loadSeoUrlsOnUnveil
    );
  };

  override componentDidMount() {
    const { match, getSeoCrawler } = this.props;
    getSeoCrawler(match.params.siteId, false);
    this.addLoadingListener();
  }

  override componentDidUpdate(prevProps: Props & ReduxProps) {
    const {
      match,
      seo: {
        crawler: { status },
      },
    } = this.props;

    clearTimeout(this.timeout);

    if (status === "pending") {
      this.startPoll();
    }

    const typeHasChanged =
      prevProps.match.params.urlType !== match.params.urlType;

    typeHasChanged && this.clearSeoUrls();

    if (prevProps.seo.crawler.status === "pending" && status === "ready") {
      this.loadSeoUrls(false);
    }

    const prevIsImport = prevProps.match.params.urlType === "import";

    prevIsImport && this.removeLoadingListener();

    if (prevIsImport && match.params.urlType !== "import") {
      this.addLoadingListener();
    }
  }

  startPoll = () => {
    const {
      getSeoCrawler,
      match,
      seo: {
        urls: { allItems },
        crawler: { errors },
      },
    } = this.props;
    this.timeout = window.setTimeout(() => {
      getSeoCrawler(match.params.siteId, true);

      if (errors && allItems.length === 0) {
        this.loadSeoUrls(false);
      }
    }, 10000);
  };

  override componentWillUnmount() {
    this.removeLoadingListener();
    window.clearTimeout(this.timeout);
  }

  startCrawl = () => {
    const { match, postSeoCrawler } = this.props;
    postSeoCrawler(match.params.siteId);
    this.startPoll();
  };

  handleRedirectSelection = (urlId: string | undefined) => {
    this.setState({
      activeUrlId: urlId,
    });
  };

  handleUrlRedirect = (urlId: string, linkData: LinkData) => {
    const { match, patchSeoUrl } = this.props;

    if (!linkData.pageId || !linkData.languageId) {
      // TODO throw error?
      return;
    }

    patchSeoUrl(match.params.siteId, urlId, {
      pageTranslationId: [linkData.pageId, linkData.languageId],
    });

    this.handleRedirectSelection(undefined);
  };

  handleUrlArchive = (urlId: string, isArchived: boolean) => {
    const { match, patchSeoUrl } = this.props;
    patchSeoUrl(match.params.siteId, urlId, {
      isArchived,
    });
  };

  handleUrlImport = (urls: string[], form: HTMLFormElement) => {
    const { postSeoUrls, match } = this.props;
    (postSeoUrls(match.params.siteId, urls) as Promise<void>).then(() => {
      form.reset();
    });
  };

  override render() {
    const {
      match,
      seo: {
        crawler: { status, crawledAt, errors },
        urls,
      },
    } = this.props;

    const seoUrl = getURL(match.params.siteId, "seo");
    const { activeUrlId } = this.state;
    const { urlType } = match.params;

    return (
      <Sidebar
        className="SeoSettings Sidebar--full"
        heading="Suchmaschinenoptimierung"
      >
        <FormInfo>
          Diese Oberfläche bietet die Möglichkeit, alte Seitenlinks, die nicht
          mehr gültig sind, auf neue Seiten umzuleiten.
          <br />
          Dabei wird ein eindeutiges Signal an Suchmaschinen gesendet, dass die
          angeforderte Seite dauerhaft unter einem anderen Link auffindbar ist.
          <br />
          <br />
          Weiterleitungen werden nach 6 Monaten automatisch gelöscht.
        </FormInfo>

        <IssuesDashboard
          checkedAt={crawledAt ? parseISO(crawledAt) : null}
          currentStatus={status}
          lastCheckCaption="Letzter Crawl"
          noIssuesMessage="Alle Urls leiten korrekt weiter."
          onStartClick={this.startCrawl}
          issuesCount={errors}
          solveIssuesMessage="Markieren Sie fehlerhafte Links als archiviert, oder leiten Sie sie auf eine bestehende Seite weiter."
          entitiesTerm="Fehler"
        />

        <div className="IssuesList">
          <ul className="Tabs">
            <Tab
              icon="error"
              isActive={urlType === "errors"}
              to={getURL(seoUrl, "errors")}
            >
              Fehler
            </Tab>
            <Tab
              icon="redirect"
              isActive={urlType === "redirects"}
              to={getURL(seoUrl, "redirects")}
            >
              Weiterleitungen
            </Tab>
            <Tab
              icon="archive"
              isActive={urlType === "archive"}
              to={getURL(seoUrl, "archive")}
            >
              Archiviert
            </Tab>
            <Tab
              icon="publish"
              isActive={urlType === "import"}
              to={getURL(seoUrl, "import")}
            >
              Import
            </Tab>
          </ul>
          {urlType !== "import" && (
            <div className="TableWrapper">
              {urls.allItems.length > 0 && (
                <table className="Table">
                  <thead>
                    <tr>
                      <th />
                      <th>Fehlerhafter Link</th>
                      <th />
                      <th>Seite</th>
                      <th>Erstmals gefunden</th>
                      <th>Zuletzt gefunden</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {urls.allItems.map((urlId) => {
                      const url = urls.byId[urlId];

                      return (
                        <SeoUrl
                          key={urlId}
                          url={url}
                          activeUrlId={this.state.activeUrlId}
                          onRedirect={this.handleRedirectSelection}
                          onArchive={this.handleUrlArchive}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}

              <div ref={this.loadingRef}>{urls.areLoading && <Spinner />}</div>
              {urls.allItems.length === 0 && (
                <div className="IssuesList__Empty">Keine Links gefunden.</div>
              )}
            </div>
          )}
          {urlType === "import" && (
            <SeoUrlImport
              isImporting={urls.isImporting}
              onSubmit={this.handleUrlImport}
            />
          )}
        </div>
        {activeUrlId && (
          <RedirectPageSelection
            urlId={activeUrlId}
            onClose={() => this.handleRedirectSelection(undefined)}
            onSelect={(linkData) =>
              this.handleUrlRedirect(activeUrlId, linkData)
            }
          />
        )}
      </Sidebar>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { seo },
  { match }
): StateProps => ({
  seo,
  queryParams: applyFilter(match.params.urlType),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getSeoCrawler: (...params: Parameters<typeof getSeoCrawler>) =>
    dispatch(getSeoCrawler(...params)),
  getSeoUrls: ({
    siteId,
    params,
    keepExisting,
  }: {
    siteId: string;
    params: GetSeoUrlsQueryParams;
    keepExisting: boolean;
  }) => dispatch(getSeoUrls({ siteId, params, keepExisting })),
  patchSeoUrl: (siteId: string, urlId: string, request: SeoUrlRequest) =>
    dispatch(patchSeoUrl(siteId, urlId, request)),
  postSeoUrls: (siteId: string, urls: string[]) =>
    dispatch(postSeoUrls(siteId, urls)),
  postSeoCrawler: (siteId: string) => dispatch(postSeoCrawler(siteId)),
  clearSeoUrlsAction: () => dispatch(getSeoUrlsStart(false)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(SeoSettings));
