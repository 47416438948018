import { FontsResponse } from "../../server/types/index.js";
import { Font, ThunkAction } from "../types/index.js";
import { fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | {
      type: "GET_FONTS_SUCCESS";
      fonts: Font[];
    }
  | { type: "GET_FONTS_START" };

export const getFonts = (): ThunkAction<Promise<void>> => {
  return (dispatch, getState) => {
    const loadState = getState().loadStates.fonts;
    if (loadState !== "unloaded") {
      return Promise.resolve();
    }

    dispatch(getFontsStart());

    return fetch({ dispatch, authorize: false })
      .get<FontsResponse>("fonts")
      .then((res) => {
        res.data;
        dispatch(getFontsSuccess(res.data.fonts));
      })
      .catch((error) => {
        dispatch(showAlert("Die Schriftarten konnten nicht geladen werden!"));
        throw error;
      });
  };
};

const getFontsStart = (): Action => ({
  type: "GET_FONTS_START",
});

export const getFontsSuccess = (fonts: Font[]): Action => ({
  type: "GET_FONTS_SUCCESS",
  fonts,
});
