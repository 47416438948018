import { useDroppable } from "@dnd-kit/core";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { DragDropType, DragDropTypes, TranslatedPage } from "../types/index.js";
import { getURL } from "../utils/utils.js";
import Icon from "./Icon.js";

interface Props {
  page: TranslatedPage;
  onPasteModule: (() => void) | undefined;
}

const EmptyModules: FunctionComponent<Props> = ({ page, onPasteModule }) => {
  const moduleLink = getURL(
    page.siteId,
    "pages",
    page.id,
    page.translation.languageId,
    "modules"
  );

  const { setNodeRef } = useDroppable({
    id: "emptyModules",
    data: {
      type: DragDropType.EmptyModules,
    } satisfies DragDropTypes[DragDropType.EmptyModules],
  });

  return (
    <div ref={setNodeRef} className="EmptyModules">
      <div className="EmptyModules__Text">
        Beginnen Sie, indem Sie eines der <Link to={moduleLink}>Module</Link> in
        die Arbeitsfläche ziehen
        {onPasteModule && (
          <>
            {" "}
            oder das{" "}
            <button
              disabled={!onPasteModule}
              onClick={() => onPasteModule?.()}
              className="Btn--bare EmptyModules__Btn"
              type="button"
            >
              Modul aus der Zwischenablage <Icon glyph="paste" /> einfügen
            </button>
          </>
        )}
        .
      </div>
    </div>
  );
};

export default EmptyModules;
