import ClassNames from "classnames";
import { FunctionComponent, ReactNode, useState } from "react";
import i18n from "../i18n/index.js";
import { Language, LoadStatus } from "../types/index.js";
import { getTranslations } from "../utils/utils.js";
import Icon from "./Icon.js";
import Spinner from "./Spinner.js";

interface Props {
  title: string;
  className: string;
  onToggleOpen: () => void;
  loadStatus: LoadStatus;
  languageId: Language;
  children: ReactNode;
}

const ToggleShowBox: FunctionComponent<Props> = ({
  title,
  className,
  onToggleOpen,
  children,
  loadStatus,
  languageId,
}) => {
  const trans = getTranslations(languageId, i18n);
  const [showContent, setShowContent] = useState(false);
  const isError = loadStatus === "error";
  const isLoading = loadStatus === "loading";
  const isLoaded = loadStatus === "loaded";

  return (
    <section className={ClassNames(className, "ToggleShowBox")}>
      <h2>
        <button
          className="Btn--bare"
          onClick={() => {
            setShowContent(!showContent);
            onToggleOpen();
          }}
        >
          {title}{" "}
          <Icon
            className="ToggleShowBox__Icon"
            glyph={showContent ? "arrow-up" : "arrow-down"}
          />
        </button>
      </h2>
      {showContent && (
        <div className="ToggleShowBox__Content">
          {isLoaded && children}
          {isLoading && <Spinner />}
          {isError && <p>{trans.errorOccurred}</p>}
        </div>
      )}
    </section>
  );
};

export default ToggleShowBox;
