import { SeoCrawlerResponse } from "../../server/types/index.js";
import {
  GetSeoUrlsQueryParams,
  SeoCrawler,
  SeoUrl,
  SeoUrlRequest,
  ThunkAction,
} from "../types/index.js";
import {
  SEOURLNotFoundError,
  calculatePagination,
  fetch,
} from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_SEO_URLS_START"; keepExisting: boolean }
  | { type: "GET_SEO_URLS_SUCCESS"; response: SeoUrl[] }
  | { type: "SET_SEO_CRAWLER_STATUS"; status: SeoCrawler["status"] }
  | {
      type: "PATCH_SEO_URL_START";
      urlId: string;
      url: SeoUrl;
      request: SeoUrlRequest;
    }
  | {
      type: "PATCH_SEO_URL_ERROR";
      urlId: string;
      previousUrl: SeoUrl;
    }
  | { type: "POST_SEO_URLS_START" }
  | { type: "POST_SEO_URLS_SUCCESS" }
  | { type: "POST_SEO_URLS_ERROR" }
  | { type: "GET_SEO_CRAWLER_SUCCESS"; crawler: SeoCrawler }
  | { type: "POST_SEO_CRAWLER_SUCCESS"; crawler: SeoCrawler };

export const getSeoUrls =
  ({
    siteId,
    params,
    keepExisting,
  }: {
    siteId: string;
    params: GetSeoUrlsQueryParams;
    keepExisting: boolean;
  }): ThunkAction<Promise<SeoUrl[]>> =>
  async (dispatch, getState) => {
    dispatch(getSeoUrlsStart(keepExisting));
    const page = calculatePagination(
      keepExisting,
      getState().seo.urls.allItems.length
    );

    const { data } = await fetch({ dispatch }).get<SeoUrl[]>(
      `sites/${siteId}/seo/urls`,
      {
        params: {
          ...params,
          page,
        },
      }
    );

    dispatch(getSeoUrlsSuccess(data));
    return data;
  };

export const getSeoUrlsStart = (keepExisting: boolean): Action => ({
  type: "GET_SEO_URLS_START",
  keepExisting,
});

const getSeoUrlsSuccess = (response: SeoUrl[]): Action => ({
  type: "GET_SEO_URLS_SUCCESS",
  response,
});

export const patchSeoUrl = (
  siteId: string,
  urlId: string,
  request: SeoUrlRequest
): ThunkAction<Promise<SeoUrl>> => {
  return async (dispatch, getState) => {
    const url = getState().seo.urls.byId[urlId];
    if (!url) throw new SEOURLNotFoundError(urlId);

    dispatch(patchSeoUrlStart(urlId, url, request));

    try {
      const res = await fetch({ dispatch }).patch(
        `sites/${siteId}/seo/urls/${urlId}`,
        request
      );
      return await Promise.resolve(res.data);
    } catch (error) {
      dispatch(patchSeoUrlError(urlId, url));
      dispatch(
        showAlert("Beim Aktualisieren der Url ist ein Fehler aufgetreten!")
      );
      throw error;
    }
  };
};

const patchSeoUrlStart = (
  urlId: string,
  url: SeoUrl,
  request: SeoUrlRequest
): Action => ({
  type: "PATCH_SEO_URL_START",
  urlId,
  url,
  request,
});

const patchSeoUrlError = (urlId: string, previousUrl: SeoUrl): Action => ({
  type: "PATCH_SEO_URL_ERROR",
  urlId,
  previousUrl,
});

export const postSeoUrls = (
  siteId: string,
  urls: string[]
): ThunkAction<Promise<void>> => {
  return async (dispatch) => {
    dispatch(postSeoUrlsStart());

    try {
      await fetch({ dispatch }).post(`sites/${siteId}/seo/urls`, urls);
      dispatch(postSeoUrlsSuccess());
      dispatch(
        showAlert(`Die Seitenlinks wurden erfolgreich importiert!`, "success")
      );
    } catch (error) {
      dispatch(postSeoUrlsError());
      dispatch(
        showAlert(
          "Der Import der Seitenlinks ist fehlgeschlagen. Überprüfen Sie die Eingabe und versuchen Sie es erneut!"
        )
      );
      throw error;
    }
  };
};

const postSeoUrlsStart = (): Action => ({
  type: "POST_SEO_URLS_START",
});

const postSeoUrlsSuccess = (): Action => ({
  type: "POST_SEO_URLS_SUCCESS",
});

const postSeoUrlsError = (): Action => ({
  type: "POST_SEO_URLS_ERROR",
});

export const getSeoCrawler =
  (siteId: string, showLastResult: boolean): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const res = await fetch({ dispatch }).get<SeoCrawlerResponse>(
      `sites/${siteId}/seo/urls/check`,
      {
        params: { showLastResult },
      }
    );
    dispatch(getSeoCrawlerSuccess(res.data));
  };

const getSeoCrawlerSuccess = (crawler: SeoCrawler): Action => ({
  type: "GET_SEO_CRAWLER_SUCCESS",
  crawler,
});

export const postSeoCrawler = (siteId: string): ThunkAction<Promise<void>> => {
  return async (dispatch) => {
    try {
      const res = await fetch({ dispatch }).post(
        `sites/${siteId}/seo/urls/check`
      );
      dispatch(postSeoCrawlerSuccess(res.data));
    } catch (error) {
      dispatch(
        showAlert(
          "Beim Überprüfen der Seite ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut, oder wenden Sie sich an den Support!"
        )
      );
      throw error;
    }
  };
};

const postSeoCrawlerSuccess = (crawler: SeoCrawler): Action => ({
  type: "POST_SEO_CRAWLER_SUCCESS",
  crawler,
});
