import ImprintModule from "../components/Modules/ImprintModule.js";
import PrivacyModule from "../components/Modules/PrivacyModule.js";
import TermsModule from "../components/Modules/TermsModule.js";
import { AllModulesByType, SystemPageModuleType } from "../types/index.js";

export const systemPageModulesByType: Pick<
  AllModulesByType,
  SystemPageModuleType
> = {
  PrivacyModule,
  TermsModule,
  ImprintModule,
};
