import { useDndContext, useDndMonitor } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import ClassNames from "classnames";
import { Fragment, FunctionComponent, useState } from "react";
import { style } from "typestyle";
import {
  DragDropType,
  Language,
  ModuleSection,
  TranslatedModule,
  TranslatedPage,
} from "../types/index.js";
import { checkIsDragDropData } from "../utils/utils.js";
import ContainerQueries from "./ContainerQueries.js";
import EmptyModules from "./EmptyModules.js";
import ModuleDropSpace from "./ModuleDropSpace.js";
import PageModule from "./PageModule.js";
import SiteInner from "./SiteInner.js";
import SortableModule from "./SortableModule.js";

interface Props {
  activeId: string | undefined;
  fontFamily: string;
  languageId: Language;
  moduleSections: ModuleSection[];
  onModuleRef: (el: HTMLDivElement | null, moduleId: string) => void;
  onModuleSelect: (
    translatedModule: TranslatedModule,
    languageId: Language
  ) => void;
  onPasteModule: (() => void) | undefined;
  page: TranslatedPage;
}

const PagePreview: FunctionComponent<Props> = ({
  activeId,
  fontFamily,
  languageId,
  moduleSections,
  onModuleRef,
  onModuleSelect,
  onPasteModule,
  page,
}) => {
  const [showModuleDropSpaces, setShowModuleDropSpaces] = useState(false);
  useDndMonitor({
    onDragStart: ({ active }) =>
      checkIsDragDropData(active.data, DragDropType.NewModule) &&
      setShowModuleDropSpaces(true),
    onDragCancel: () => setShowModuleDropSpaces(false),
    onDragEnd: () => setShowModuleDropSpaces(false),
  });

  const { active } = useDndContext();

  return (
    <div
      className={ClassNames("Page__Preview", style({ fontFamily }), {
        "Page__Preview--dragging": active,
      })}
      lang={languageId}
    >
      <ContainerQueries
        className="Page__Preview__Inner"
        useViewportWidth={false}
      >
        {(queries) => (
          <>
            <SiteInner isPreview={true}>
              {moduleSections.map(({ isSortable, items }, index) => {
                if (items.length === 0 && isSortable) {
                  return (
                    <EmptyModules
                      onPasteModule={onPasteModule}
                      key={0}
                      page={page}
                    />
                  );
                }

                return isSortable ? (
                  <SortableContext key={index} items={items}>
                    {items.map((module, index) => (
                      <Fragment key={module.id}>
                        {showModuleDropSpaces && (
                          <ModuleDropSpace index={index} />
                        )}
                        <SortableModule
                          activeId={activeId}
                          index={index}
                          languageId={languageId}
                          module={module}
                          onModuleSelect={onModuleSelect}
                          onRef={(node) => onModuleRef(node, module.id)}
                          page={page}
                          queries={queries}
                        />
                        {showModuleDropSpaces && index + 1 === items.length && (
                          <ModuleDropSpace index={index + 1} />
                        )}
                      </Fragment>
                    ))}
                  </SortableContext>
                ) : (
                  items.map((module, index) => (
                    <PageModule
                      activeId={activeId}
                      index={index}
                      key={module.id}
                      languageId={languageId}
                      module={module}
                      onModuleSelect={onModuleSelect}
                      page={page}
                      queries={queries}
                      ref={(node) => onModuleRef(node, module.id)}
                      isDragging={false}
                      insertPosition={undefined}
                    />
                  ))
                );
              })}
            </SiteInner>
          </>
        )}
      </ContainerQueries>
    </div>
  );
};

export default PagePreview;
