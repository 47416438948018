import "lazysizes";
import { createRoot } from "react-dom/client";
import App from "./components/App.js";
import { adminReducer } from "./reducers/index.js";
import configureStore from "./store/configureStore.js";
import { StoreState } from "./types/index.js";
import { registerErrorReporting } from "./utils/errorReporting.js";

registerErrorReporting();

const initialState = adminReducer({} as StoreState, { type: "@@INIT" });
const store = configureStore(initialState);
const rootEl = document.getElementById("root");
const root = createRoot(rootEl!);

root?.render(<App {...{ store }} />);
