import { useDroppable } from "@dnd-kit/core";
import ClassNames from "classnames";
import { FunctionComponent } from "react";

export interface Props {
  pageId: string;
  isFirst: boolean;
  isNewSubtree: boolean;
}

const PageTreeItemDropTarget: FunctionComponent<Props> = (props) => {
  const { pageId, isFirst, isNewSubtree } = props;
  const { isOver, setNodeRef } = useDroppable({
    // Construct an unique id.
    id: pageId + isFirst + isNewSubtree,
    data: props,
  });

  return (
    <div
      ref={setNodeRef}
      className={ClassNames("PageTreeItem__DropTarget", {
        "PageTreeItem__DropTarget--top": isFirst,
        "PageTreeItem__DropTarget--is-over": isOver,
      })}
    />
  );
};

export default PageTreeItemDropTarget;
