import { FunctionComponent } from "react";
import Sidebar from "./Sidebar.js";
import Tutorial from "./Tutorial.js";

const Help: FunctionComponent<{}> = () => {
  return (
    <Sidebar className="Help Sidebar--full" heading="Anleitung">
      <div className="Tutorials">
        <Tutorial
          heading="Aufbau des Webseitentools"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/aufbau-des-webseitentools.mp4"
        />
        <Tutorial
          heading="Hautpmenüpunkt „Seiten“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/hauptmenuepunkt-seiten.mp4"
        />
        <Tutorial
          heading="Hauptmenüpunkt „Seitenmodule“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/hauptmenuepunkt-seitenmodule.mp4"
        />
        <Tutorial
          heading="„Header“, „Footer“ und „Systemseiten“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/header-footer-und-systemseiten.mp4"
        />
        <Tutorial
          heading="„Bilder“, „Text-im-Bild“ und „Bildergalerie“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/bilder-bildergalerie.mp4"
        />
        <Tutorial
          heading="„Text“ und „Trennmodul“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/text-und-trennmodul.mp4"
        />
        <Tutorial
          heading="„Specials“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/specials.mp4"
        />
        <Tutorial
          heading="„Buchungswidget“ und „Anfrageformular“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/buchungswidget-anfrageformular.mp4"
        />
        <Tutorial
          heading="„Wetter &amp; Webcam“ und „Pop-Up“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/wetter_webcam_popup.mp4"
        />
        <Tutorial
          heading="„Google 360°-Ansichten“ und „Newslettermodul“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/google-360-ansichten-und-newslettermodul.mp4"
        />
        <Tutorial
          heading="Peer.tv"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/peer-tv.mp4"
        />
        <Tutorial
          heading="Neuheiten Modul „Highlights“"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/neuheiten-modul-highlights.mp4"
        />
        <Tutorial
          heading="Routen im Textmodul"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/routen-im-textmodul.mp4"
        />
        <Tutorial
          heading="Austattungsmerkmale und Zitat"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/austattungsmerkmale-und-zitat.mp4"
        />
        <Tutorial
          heading="Neue Funktionen für das Bildmodul"
          src="https://storage.googleapis.com/sites.hgv.it/tutorials/neue-funktionen-bildmodul.mp4"
        />
      </div>
    </Sidebar>
  );
};

export default Help;
