import { Dispatch, FunctionComponent, SetStateAction, useState } from "react";
import { CopyrightsAPI } from "../../server/types/index.js";
import { ColorScheme, Language, LoadStatus } from "../types/index.js";
import { trackErrorWithService } from "../utils/errorReporting.js";
import { fetch } from "../utils/utils.js";
import IconsCopyright from "./IconsCopyright.js";
import ImagesCopyright from "./ImagesCopyright.js";

interface Props {
  siteId: string;
  languageId: Language;
  scheme: ColorScheme;
}

const fetchCopyrights = async ({
  siteId,
  languageId,
  setLoadStatus,
  setCopyrightsAPIResponse,
}: {
  siteId: string;
  languageId: Language;
  setLoadStatus: Dispatch<SetStateAction<LoadStatus>>;
  setCopyrightsAPIResponse: Dispatch<SetStateAction<CopyrightsAPI>>;
}): Promise<void> => {
  setLoadStatus("loading");
  try {
    const { data } = await fetch({
      dispatch: undefined,
      languageId,
      authorize: false,
    }).get(`sites/${siteId}/copyrights`);
    setCopyrightsAPIResponse(data);
    setLoadStatus("loaded");
  } catch (error) {
    setLoadStatus("error");
    error instanceof Error && trackErrorWithService(error);
  }
};

const Copyrights: FunctionComponent<Props> = ({
  siteId,
  languageId,
  scheme,
}) => {
  const [loadStatus, setLoadStatus] = useState<LoadStatus>("unloaded");
  const [{ icons, pictures }, setCopyrightsAPIResponse] =
    useState<CopyrightsAPI>({
      icons: [],
      pictures: [],
    });

  const onToggleOpen = () => {
    if (loadStatus !== "unloaded") return;
    fetchCopyrights({
      languageId,
      setCopyrightsAPIResponse,
      setLoadStatus,
      siteId,
    });
  };

  return (
    <>
      <ImagesCopyright
        pictures={pictures}
        onToggleOpen={onToggleOpen}
        loadStatus={loadStatus}
        languageId={languageId}
      />
      <IconsCopyright
        iconIds={icons}
        onToggleOpen={onToggleOpen}
        loadStatus={loadStatus}
        scheme={scheme}
        languageId={languageId}
      />
    </>
  );
};

export default Copyrights;
