import { FunctionComponent } from "react";
import { connect } from "react-redux";
import { getActiveSite } from "../selectors/sites.js";
import {
  AnchorAttrs,
  Language,
  LinkData,
  LinkParams,
  ModuleType,
  StoreState,
} from "../types/index.js";
import {
  checkIsExternalLink,
  getFallbackLanguage,
  getModuleIcon,
  getModuleTypeTranslation,
  resolveLink,
} from "../utils/utils.js";
import Icon from "./Icon.js";
import SmartLink from "./SmartLink.js";

interface Props {
  link: LinkData;
  languageId: Language;
}

interface StateProps {
  resolvedLink: AnchorAttrs;
  moduleType: ModuleType | null;
}

const PageLinkPreview: FunctionComponent<Props & StateProps> = ({
  resolvedLink,
  resolvedLink: { href },
  moduleType,
}) => {
  const link = <SmartLink anchorAttrs={resolvedLink} isPreview={true} />;

  return (
    <div className="EmptyModules">
      <div>
        {!href && (
          <>
            Dies ist ein <Icon glyph="folder" /> Ordner.
          </>
        )}

        {href && (
          <>
            Dies ist ein Link auf{" "}
            {moduleType ? (
              <>
                ein <Icon glyph={getModuleIcon(moduleType)} />{" "}
                {getModuleTypeTranslation(moduleType)} Modul der Seite {link}.
              </>
            ) : (
              <>{checkIsExternalLink(href) ? link : <>die Seite {link}</>}</>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (
  { pages, sites }: StoreState,
  { link: { pageId, moduleId, url, moduleType }, languageId }: Props
): StateProps => {
  const site = getActiveSite(sites);

  const linkParams: LinkParams = {
    pageId,
    moduleId,
    languageId,
    isPreview: true,
    pages,
    url,
    fallbackLanguageId: getFallbackLanguage(site, languageId),
  };

  const resolvedLink = resolveLink(linkParams);

  return {
    resolvedLink,
    moduleType,
  };
};

export default connect(mapStateToProps)(PageLinkPreview);
