import classNames from "classnames";
import { FunctionComponent, useEffect } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { getModules } from "../actions/Modules.js";
import { getPageModules } from "../selectors/modules.js";
import {
  Language,
  Module,
  ModuleType,
  Page,
  PageTranslation,
  StoreState,
} from "../types/index.js";
import { getModuleIcon, getModulePreviewText } from "../utils/utils.js";
import FormInfo from "./FormInfo.js";
import Icon from "./Icon.js";

interface Props {
  onModuleSelect: (params: { id: string; type: ModuleType }) => void;
  pageId: string;
  languageId: Language;
  activeModuleId: string | null;
}

interface StateProps {
  page: Page | undefined;
  pageTranslation?: PageTranslation;
  modules: Module[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const ModuleTree: FunctionComponent<Props & ReduxProps> = ({
  page,
  getModules,
  modules,
  activeModuleId,
  onModuleSelect,
  languageId,
}) => {
  useEffect(() => {
    page &&
      getModules({ siteId: page.siteId, pageId: page.id, forceLoad: false });
  });

  const hasModules = modules.length > 0;

  return (
    <div className="PageTree__Container">
      {!hasModules && (
        <FormInfo>Keine Module für die gewählte Seite gefunden.</FormInfo>
      )}

      {hasModules && (
        <div className="PageTree__Wrapper">
          <ul className="PageTree">
            {modules.map((module) => (
              <li className="PageTree__Item" key={module.id}>
                <Icon
                  glyph={getModuleIcon(module.type)}
                  className="PageTree__Symbol PageTree__Icon"
                />
                <button
                  type="button"
                  onClick={() =>
                    onModuleSelect({ id: module.id, type: module.type })
                  }
                  className={classNames("PageTree__Link", {
                    "PageTree__Link--active": module.id === activeModuleId,
                  })}
                >
                  {getModulePreviewText(module, languageId)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules, pages },
  { pageId, languageId }
): StateProps => {
  // Filter out all modules which aren’t translated in this language
  // TODO: refactor this
  const pageModules = getPageModules(modules, pageId).filter(
    ({ translations }) => languageId in translations
  );

  return {
    page: pages.byId[pageId],
    modules: pageModules,
  };
};

const mapDispatchToProps = {
  getModules,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ModuleTree);
