import { isAxiosError } from "axios";
import {
  PostPublishStatus,
  Publish,
  ServerError,
  ThunkAction,
} from "../types/index.js";
import { fetch } from "../utils/utils.js";
import { showAlert } from "./Alerts.js";

export type Action =
  | { type: "GET_PUBLISH_SUCCESS"; publish: Publish }
  | { type: "POST_PUBLISH_SUCCESS"; publish: Publish };

export const getPublishStatus =
  ({
    siteId,
    showLastResult,
  }: {
    siteId: string;
    showLastResult: boolean;
  }): ThunkAction<Promise<Action>> =>
  async (dispatch) => {
    const { data } = await fetch({ dispatch }).get<Publish>(
      `sites/${siteId}/publish`,
      { params: { showLastResult } }
    );
    return dispatch(getPublishSuccess(data));
  };

const getPublishSuccess = (publish: Publish): Action => ({
  type: "GET_PUBLISH_SUCCESS",
  publish,
});

export const postPublish =
  (siteId: string): ThunkAction<Promise<PostPublishStatus>> =>
  async (dispatch) => {
    try {
      const { data } = await fetch({ dispatch }).post<Publish>(
        `sites/${siteId}/publish`
      );
      dispatch(postPublishSuccess(data));
      return "success";
    } catch (error) {
      const isCriticalIssuesError =
        isAxiosError<ServerError>(error) &&
        error.response?.data.error === "critical errors found";

      if (isCriticalIssuesError) return "criticalIssues";

      dispatch(
        showAlert(
          "Beim Veröffentlichen der Seite ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut, oder wenden Sie sich an den Support!"
        )
      );
      throw error;
    }
  };

const postPublishSuccess = (publish: Publish): Action => ({
  type: "POST_PUBLISH_SUCCESS",
  publish,
});
