import { FunctionComponent, useState } from "react";
import { type LoginForm } from "../types/index.js";
import FormError from "./FormError.js";
import Spinner from "./Spinner.js";

interface Props {
  isSubmitting: boolean;
  loginError: string | undefined;
  onSubmit: (form: LoginForm) => void;
}

const LoginForm: FunctionComponent<Props> = ({
  isSubmitting,
  loginError,
  onSubmit,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <form
      className="Login__Form Form"
      onSubmit={(e) => {
        e.preventDefault();
        if (isSubmitting) return;
        onSubmit({ email, password });
      }}
    >
      <h1 className="Login__Title">HGV-Webseiten</h1>
      <div className="Form__Field">
        <input
          type="email"
          name="email"
          required
          placeholder="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="Form__Field">
        <input
          type="password"
          name="password"
          required
          placeholder="Passwort"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="Form__Field Login__Submit">
        <button
          type="submit"
          className="Btn Btn--action"
          disabled={isSubmitting}
        >
          {isSubmitting ? <Spinner /> : <span>Anmelden</span>}
        </button>
      </div>
      <FormError error={loginError} />
    </form>
  );
};

export default LoginForm;
