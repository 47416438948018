import { FunctionComponent } from "react";
import { connect, MapStateToProps } from "react-redux";
import { uploadFavicon } from "../actions/Sites.js";
import { getActiveSite } from "../selectors/sites.js";
import { ColorScheme, StoreState } from "../types/index.js";
import { getActiveColorScheme } from "../utils/utils.js";
import FileUpload from "./FileUpload.js";
import FormInfo from "./FormInfo.js";

interface Props {}

interface StateProps {
  scheme: ColorScheme;
  faviconURL: string | undefined;
  siteId: string;
  isAdminUser: boolean;
}

interface DispatchProps {
  uploadFavicon: (siteId: string, file: File) => Promise<void>;
}

const FaviconSelection: FunctionComponent<
  Props & StateProps & DispatchProps
> = ({ uploadFavicon, isAdminUser, siteId, faviconURL }) => (
  <div className="Form">
    <div className="Form__Field">
      <div className="Form__Content">
        {faviconURL ? (
          <img
            width="32"
            height="32"
            src={faviconURL}
            alt="Favicon"
            loading="lazy"
          />
        ) : (
          "Kein Favicon hochgeladen"
        )}
      </div>
    </div>

    {isAdminUser && (
      <FileUpload
        htmlId="favicon-file-upload"
        label="Favicon hochladen"
        // 20 KiB
        maxSize={20 * 1024}
        acceptFileExtensions={["svg"]}
        onUpload={async (file) => uploadFavicon(siteId, file)}
        fileUploadMessage="Favicon hierher ziehen oder"
      >
        <FormInfo>
          Es können nur quadratische SVG-Icons mit einer Dateigröße von maximal
          20 KiB hochgeladen werden.
        </FormInfo>
      </FileUpload>
    )}
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = ({
  colorSchemes,
  sites,
  user,
}) => {
  const site = getActiveSite(sites);
  const scheme = getActiveColorScheme(colorSchemes, site);

  return {
    scheme,
    siteId: site.id,
    isAdminUser: !!user.isAdmin,
    faviconURL: site.favicon.uploadURL ?? undefined,
  };
};

const mapDispatchToProps = { uploadFavicon };

export default connect(mapStateToProps, mapDispatchToProps)(FaviconSelection);
