import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import LoginPage from "./LoginPage.js";

const LoginImprint: FunctionComponent = () => {
  return (
    <LoginPage wrapperClassName="Login__Imprint">
      <Link to="/login">zurück zum Login</Link>
      <h1>Impressum</h1>
      <h2>CMS: technische Umsetzung, Konzept &amp; Design</h2>
      <address className="Login__Imprint__Address">
        HGV Service Genossenschaft
        <br />
        Schlachthofstraße 59
        <br />
        I-39100 Bozen
        <br />
        Tel. <a href="tel:00390471317840">+39 0471 317 840</a>
        <br />
        Fax +39 0471 317 701
        <br />
        <a href="mailto:info@bookingsuedtirol.com">info@bookingsuedtirol.com</a>
        <br />
        Mwst.-Nr.: 00576540215
      </address>
      <h2>Informationsschreiben über Cookies</h2>
      <h3>Was ist ein Cookie?</h3>
      <p>
        Ein Cookie ist eine kleine Textdatei, die beim Besuch einer Website von
        Ihrem Internetbrowser heruntergeladen wird. Beim nächsten Aufruf dieser
        Website mit demselben Endgerät wird das Cookie entweder an die Webseite
        zurückgesandt, die sie erzeugt hat (First Party Cookie) oder an eine
        andere Webseite gesandt, zu der es gehört (Third Party Cookie). Dadurch
        erkennt die Website, dass sie mit diesem Browser schon einmal aufgerufen
        wurde, und kann so die Benutzererfahrung beim erneuten Aufruf
        verbessern. Cookies erinnern sich z. B. an Ihre Vorlieben und passen die
        angezeigten Angebote Ihren persönlichen Interessen und Bedürfnissen an.
        Abhängig von ihrer Funktion und ihrem Verwendungszweck können Cookies in
        unterschiedliche Kategorien eingeteilt werden: funktionelle Cookies,
        Cookies zu Marketingzwecken (Profiling) und Cookies von Drittanbietern.
      </p>
      <h3>Technische Cookies</h3>
      <p>Für diese Cookies ist keine Zustimmung des Benutzers notwendig.</p>
      <p>
        Diese Cookies gewährleisten Funktionen, ohne die Sie diese Webseite
        nicht korrekt nutzen können. Diese Cookies werden ausschließlich auf
        dieser Website verwendet und sind deshalb sogenannte First Party
        Cookies. Sie werden lediglich während der aktuellen Session auf Ihrem
        Computer gespeichert. Zu den technischen Cookies gehören auch die
        analytischen Cookies, die für Zugriffsstatistiken verwendet werden, die
        Informationen in allgemeiner Form sammeln und nicht die Identifizierung
        des Besuchers ermöglichen.
      </p>
      <h3>Profilierungs-Cookies</h3>
      <p>
        Für diese Cookies ist die Zustimmung des Benutzers notwendig. Mit
        Profiling Cookies werden Benutzerdaten gesammelt, um dem selben Benutzer
        anschließend Werbebotschaften zu senden, die im Einklang mit seinen
        Interessen stehen.
      </p>
      <h3>Cookies von Drittanbietern</h3>
      <p>
        Da es sich bei den hier verwendeten Cookies um Cookies anderer Websites
        handelt (sog. Cookies von Drittanbietern), finden Sie auf der folgenden
        Cookie-Liste die Informationen bezüglich Zustimmung oder Ablehnung einer
        eventuellen Nutzung.
      </p>
      <a
        href="https://de.wikipedia.org/wiki/HTTP-Cookie"
        target="_blank"
        rel="noopener"
      >
        Weitere Infos über Cookies
      </a>
      <h2>Cookies auf dieser Website</h2>
      <h3>Technische Cookies</h3>
      <p>
        Zum Beispiel Cookies zum Aufrechterhalten einer Sitzung, zur Verwaltung
        von Reaktionszeit, Performance oder Validierung von Optionen, zur
        Verwendung von Sicherheitselementen, zum Teilen von Inhalten auf
        sozialen Netzwerken usw.
      </p>
      <h3>Profilierungs-Cookies</h3>
      Google Remarketing Cookie
      <h3>Cookies von Drittanbietern</h3>
      Youtube, Google Analytics, Google Maps, Google Plus, Facebook, Twitter
      <h2>Wie kann ich Cookies deaktivieren?</h2>
      <p>
        Um die Cookies zu deaktivieren, zu löschen oder zu blockieren ist es
        möglich die Einstellungen der Browser, wie unter den folgenden Links
        beschrieben, zu ändern. Im Falle einer Deaktivierung ist die
        vollständige Nutzung dieser Webseite nicht mehr garantiert.
      </p>
      <p>Google Chrome, Firefox, Safari, Microsoft Edge</p>
      <p>
        Wenn Sie keinen der oben genannten Browser verwenden, wählen Sie die
        Option „Cookies“ in der „Hilfe“-Funktion, um zu erfahren, wo Ihre
        Cookies- Einstellungen zu finden sind. Cookies von Dritten können vom
        Benutzer auch einzeln deaktiviert bzw. gelöscht werden. Die
        entsprechende Anleitung finden Sie auf der Website der European
        Interactive Digital Advertising Alliance:{" "}
        <a
          target="_blank"
          rel="noopener"
          href="https://www.youronlinechoices.eu"
        >
          www.youronlinechoices.eu
        </a>
        .
      </p>
      <Link to="/login">zurück zum Login</Link>
    </LoginPage>
  );
};

export default LoginImprint;
