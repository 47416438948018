import { FunctionComponent } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Alert as AlertType } from "../types/index.js";
import Alert from "./Alert.js";

interface Props {
  alerts: AlertType[];
  onClose: (id: string) => void;
}

const Alerts: FunctionComponent<Props> = ({ alerts, onClose }) => (
  <div className="Alerts">
    <TransitionGroup>
      {alerts.map((alert) => (
        <CSSTransition
          key={alert.id}
          timeout={{ enter: 4000, exit: 500 }}
          classNames=""
        >
          {(status) => (
            <Alert
              className={`Alert Alert--${status} Alert--${alert.type}`}
              alert={alert}
              onClose={onClose}
            />
          )}
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
);

export default Alerts;
