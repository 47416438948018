import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { postLogin } from "../actions/Login.js";
import { LoginState, ServerError, StoreState, User } from "../types/index.js";
import { getURL, isAuthenticated } from "../utils/utils.js";
import LoginForm from "./LoginForm.js";
import LoginPage from "./LoginPage.js";

const getErrorMessage = (error: ServerError): string =>
  error.statusCode === 401
    ? "Das Passwort und/oder der Benutzername sind nicht korrekt."
    : "Beim Anmelden ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.";

interface StateProps {
  login: LoginState;
  user: User;
}

type ReduxProps = ConnectedProps<typeof connector>;

const Login: FunctionComponent<ReduxProps> = ({
  login: { error, isSubmitting },
  user,
  postLogin,
}) => {
  const location = useLocation();
  const history = useHistory();

  if (isAuthenticated(user)) {
    const redirectURL = new URLSearchParams(location.search).get("redirect");
    history.replace({ pathname: redirectURL ?? getURL(user.siteId) });
    return null;
  }

  return (
    <LoginPage wrapperClassName="Login__Wrapper--form">
      <img className="Login__Logo" src="/hgv.svg" alt="" />
      <LoginForm
        isSubmitting={isSubmitting}
        loginError={error ? getErrorMessage(error) : undefined}
        onSubmit={postLogin}
      />
      <footer className="Login__Footer">
        <ul className="Nav">
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>Mwst.-Nr.: 00576540215</li>
        </ul>
      </footer>
    </LoginPage>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = ({
  login,
  user,
}): StateProps => ({
  login,
  user,
});

const mapDispatchToProps = {
  postLogin,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Login);
