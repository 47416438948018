import { FunctionComponent, useEffect, useRef } from "react";
import { ConnectedProps, MapStateToProps, connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  changeColor,
  deleteColorSchemeStart,
  putColorScheme,
  putColorSchemeStart,
  updateColorScheme,
} from "../actions/ColorSchemes.js";
import { getActiveSite } from "../selectors/sites.js";
import {
  ColorScheme as ColorSchemeType,
  Language,
  ModuleType,
  StoreState,
} from "../types/index.js";
import {
  disabledModuleColors,
  getActiveColorScheme,
  getURL,
  keys,
} from "../utils/utils.js";
import ColorSelection from "./ColorSelection.js";
import Sidebar from "./Sidebar.js";

type Props = RouteComponentProps<{
  siteId: string;
  pageId: string;
  languageId: Language;
  colorSchemeId: string;
  moduleId?: string;
}>;

interface StateProps {
  colorSchemeId: string;
  scheme: ColorSchemeType;
  closeLink: string;
  isExistingScheme: boolean;
  moduleType: ModuleType | undefined;
}

type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = Props & ReduxProps;

const titles = {
  main: "Allgemein",
  primary: "Primärfarben",
  secondary: "Sekundärfarben",
};

const ColorSchemeSettings: FunctionComponent<AllProps> = ({
  scheme,
  isExistingScheme,
  colorSchemeId,
  putColorSchemeStart,
  updateColorScheme,
  deleteColorSchemeStart,
  history,
  putColorScheme,
  match,
  closeLink,
  moduleType,
  changeColor,
}) => {
  const unsavedSchemeRef = useRef(scheme);
  const isExistingSchemeRef = useRef(isExistingScheme);
  const isSavedRef = useRef(false);

  const handleColorSchemePut = () => {
    putColorScheme(match.params.siteId, colorSchemeId);

    // TODO: handle things if upserting the scheme to the API fails
    unsavedSchemeRef.current = scheme;
    isSavedRef.current = true;

    history.push(closeLink);
  };

  const checkIsColorDisabledForModule = (
    categoryId: keyof ColorSchemeType,
    subId: keyof ColorSchemeType[keyof ColorSchemeType]
  ) =>
    moduleType
      ? disabledModuleColors[categoryId][subId].indexOf(moduleType) !== -1
      : false;

  useEffect(() => {
    // Create the color scheme if it does not already exist
    !isExistingSchemeRef.current && putColorSchemeStart(colorSchemeId, scheme);

    return () => {
      // Delete the new color scheme if it wasn’t saved
      if (!isSavedRef.current && !isExistingSchemeRef.current) {
        deleteColorSchemeStart(colorSchemeId);
        return;
      }

      updateColorScheme(colorSchemeId, unsavedSchemeRef.current);
    };
  }, []);

  return (
    <Sidebar
      className="ColorSchemeSettings"
      heading="Farbschema"
      closeLink={closeLink}
    >
      {keys(scheme).map((categoryId) => {
        const categoryColors = scheme[categoryId];

        return (
          <div key={categoryId} className="ColorSchemeSettings__ColorCategory">
            <div className="ColorSchemeSettings__ColorCategory__Title">
              {titles[categoryId]}
            </div>

            <div className="ColorSchemeSettings__ColorCategory__Colors">
              {keys(categoryColors).map((subId) => {
                const combinedId = categoryId + "." + subId;
                const color = categoryColors[subId];
                const disabled = checkIsColorDisabledForModule(
                  categoryId,
                  subId
                );

                return (
                  <ColorSelection
                    key={combinedId}
                    combinedId={combinedId}
                    id={subId}
                    disabled={disabled}
                    color={color}
                    onChange={(color) =>
                      changeColor(colorSchemeId, categoryId, subId, color)
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      <button
        className="Btn Btn--action ColorSchemeSettings__Save"
        onClick={handleColorSchemePut}
      >
        Speichern
      </button>
    </Sidebar>
  );
};

const getCloseLink = (
  baseLinkParams: string[],
  moduleId: string | undefined
) => {
  if (moduleId) {
    return getURL(...baseLinkParams, "modules", moduleId);
  }
  return getURL(...baseLinkParams, "design");
};

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { sites, colorSchemes, modules },
  {
    match: {
      params: { siteId, pageId, languageId, colorSchemeId, moduleId },
    },
  }
): StateProps => {
  const baseLinkParams = [siteId, "pages", pageId, languageId];
  const site = getActiveSite(sites);

  const scheme = getActiveColorScheme(colorSchemes, site, {
    colorSchemeId,
  });

  const isExistingScheme = colorSchemeId in colorSchemes.schemes;
  const moduleType =
    moduleId && modules.byId[moduleId]
      ? modules.byId[moduleId].type
      : undefined;

  return {
    colorSchemeId: colorSchemeId,
    scheme,
    closeLink: getCloseLink(baseLinkParams, moduleId),
    isExistingScheme: isExistingScheme,
    moduleType,
  };
};

const mapDispatchToProps = {
  putColorScheme,
  putColorSchemeStart,
  changeColor,
  updateColorScheme,
  deleteColorSchemeStart,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(ColorSchemeSettings));
