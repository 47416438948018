import {
  ChangeEventHandler,
  DragEventHandler,
  FunctionComponent,
  ReactNode,
} from "react";
import { ConnectedProps, connect } from "react-redux";
import { showAlert } from "../actions/Alerts.js";
import Icon from "./Icon.js";

type ReduxProps = ConnectedProps<typeof connector>;

const checkMatchesExtension = (extensions: string[], filename: string) =>
  extensions.some((extension) => filename.endsWith("." + extension));

interface Props {
  label: string;
  fileUploadMessage: string;
  htmlId: string;
  acceptFileExtensions: string[];
  onUpload: (file: File) => void;
  maxSize: number;
  children: ReactNode;
}

const FileUpload: FunctionComponent<Props & ReduxProps> = ({
  htmlId,
  label,
  fileUploadMessage,
  acceptFileExtensions,
  showAlert,
  onUpload,
  maxSize,
  children,
}) => {
  const handleFile = (file: File) => {
    const isAllowedExtension = checkMatchesExtension(
      acceptFileExtensions,
      file.name
    );

    if (!isAllowedExtension) {
      showAlert(
        "Falscher Dateityp. Laden Sie einen der erlaubten Dateitypen hoch.",
        "error"
      );
      return;
    }

    if (file.size > maxSize) {
      showAlert(
        `Die Datei überschreitet die Maximalgröße von ${maxSize / 1024} KiB.`,
        "error"
      );
      return;
    }

    onUpload(file);
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { files },
  }) => files && handleFile(files[0]);

  const onDrop: DragEventHandler<HTMLDivElement> = ({
    dataTransfer: { files },
  }) => files && handleFile(files[0]);

  return (
    <div className="Form__Field">
      <div
        onDrop={onDrop}
        className="Form__Content Form__Content--center Form__FileUpload"
      >
        <span className="Form__FileUploadMessage">{fileUploadMessage}</span>
        <input
          className="Form__FileUploadInput"
          id={htmlId}
          onChange={onFileChange}
          accept={acceptFileExtensions.map((value) => "." + value).join(",")}
          type="file"
        />
        <button className="Btn Form__FileUploadButton" type="button">
          <Icon glyph="plus-sign" /> Hochladen
        </button>
      </div>
      {children}
      <div className="Form__Label">
        <label htmlFor={htmlId}>{label}</label>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  showAlert,
};

const connector = connect(undefined, mapDispatchToProps);

export default connector(FileUpload);
