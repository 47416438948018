import ClassNames from "classnames";
import { FunctionComponent } from "react";
import { connect, ConnectedProps, MapStateToProps } from "react-redux";
import {
  Language,
  Module,
  PopUpModuleSettings,
  StoreState,
} from "../types/index.js";
import {
  getModulePreviewText,
  getTranslationLanguage,
} from "../utils/utils.js";
import Icon from "./Icon.js";
import ItemsListButton from "./ItemsListButton.js";

interface Props {
  label: string;
  onAdd?: () => void;
  onRemove?: (moduleId: string) => void;
  onTranslate?: (moduleId: string, sourceLanguageId: Language) => void;
  onEdit?: (moduleId: string) => void;
  onSetActive: (moduleId: string) => void;
  onDuplicate?: (moduleId: string) => void;
  activeId: string | null;
  languageId: Language;
}

export interface PopUpListItem {
  id: string;
  title: string;
  translateFrom: Language | undefined;
}

interface StateProps {
  listItems: PopUpListItem[];
}

type ReduxProps = ConnectedProps<typeof connector>;

const PopUpsList: FunctionComponent<Props & ReduxProps> = ({
  onAdd,
  onRemove,
  onTranslate,
  onEdit,
  onSetActive,
  onDuplicate,
  listItems,
  activeId,
  label,
}) => (
  <div className="Form__Field">
    <div className="Form__Content Form__Content--compact Form__Content--column">
      <ul className="ControlItemsList">
        {listItems.map(({ id: moduleId, title, translateFrom }) => {
          return (
            <li
              key={moduleId}
              className={ClassNames("ControlItem", {
                "ControlItem--semi-transparent": !!translateFrom,
              })}
            >
              <div className="ControlItem__Inner">
                <div className="ControlItem__Preview">
                  <div className="ControlItem__PreviewInner">
                    <div className="ControlItem__Title">
                      <span
                        className="ControlItem__Caption"
                        title="Lorem ipsum"
                      >
                        {title}
                      </span>
                    </div>
                  </div>
                </div>

                {translateFrom && onTranslate ? (
                  <ItemsListButton
                    title="Übersetzen"
                    icon="translate"
                    onClick={() => onTranslate(moduleId, translateFrom)}
                  />
                ) : (
                  <>
                    {onEdit && (
                      <ItemsListButton
                        title="Bearbeiten"
                        icon="edit"
                        onClick={() => onEdit(moduleId)}
                      />
                    )}

                    {onRemove && (
                      <ItemsListButton
                        title="Löschen"
                        icon="delete"
                        onClick={() => onRemove(moduleId)}
                      />
                    )}

                    {onDuplicate && (
                      <ItemsListButton
                        title="Duplizieren"
                        icon="duplicate"
                        onClick={() => onDuplicate(moduleId)}
                      />
                    )}

                    <ItemsListButton
                      title={
                        activeId === moduleId ? "Deaktivieren" : "Aktivieren"
                      }
                      icon="check"
                      isActive={activeId === moduleId}
                      onClick={() => onSetActive(moduleId)}
                    />
                  </>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      {onAdd && (
        <button onClick={onAdd} className="Btn Form__Content__Button">
          <Icon glyph="add" /> Hinzufügen
        </button>
      )}
    </div>

    <div className="Form__Label">
      <label>{label}</label>
    </div>
  </div>
);

const mapStateToProps: MapStateToProps<StateProps, Props, StoreState> = (
  { modules },
  { languageId }
): StateProps => {
  const listItems = (
    modules.bySiteModuleType.PopUpModule ?? []
  ).map<PopUpListItem>((moduleId) => {
    const popUpModule = modules.byId[moduleId] as Module<PopUpModuleSettings>;
    const translationLanguageId = getTranslationLanguage(
      popUpModule,
      languageId
    );
    const isUntranslated = translationLanguageId !== languageId;
    const previewText = getModulePreviewText(
      popUpModule,
      translationLanguageId
    );
    return {
      id: moduleId,
      title: isUntranslated
        ? `[${translationLanguageId.toUpperCase()}] ${previewText}`
        : previewText,
      translateFrom: isUntranslated ? translationLanguageId : undefined,
    };
  });

  return { listItems };
};

const connector = connect(mapStateToProps);

export default connector(PopUpsList);
