import { Language, ThunkAction } from "../types/index.js";
import { fetch } from "../utils/utils.js";

export type Action =
  | { type: "GET_LANGUAGES_SUCCESS"; languages: Language[] }
  | { type: "GET_LANGUAGES_ERROR"; error: Error };

export const getLanguages = (): ThunkAction<Promise<Action>> => {
  return (dispatch) => {
    return fetch({ dispatch, authorize: false })
      .get("languages")
      .then((res) => dispatch(getLanguagesSuccess(res.data.languages)))
      .catch((error) => {
        dispatch(getLanguagesError(error));
        throw error;
      });
  };
};

const getLanguagesSuccess = (languages: Language[]): Action => ({
  type: "GET_LANGUAGES_SUCCESS",
  languages,
});

const getLanguagesError = (error: Error): Action => ({
  type: "GET_LANGUAGES_ERROR",
  error,
});
