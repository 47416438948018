import { FunctionComponent } from "react";

interface Props {
  translation?: string | null;
}

const FormTranslation: FunctionComponent<Props> = ({ translation }) =>
  translation ? (
    <div className="Form__Translation" title="Ursprungstext">
      {translation}
    </div>
  ) : null;

export default FormTranslation;
